import { Button } from "@mui/material";
import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/zoom.css";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaAngleDown } from "react-icons/fa6";
import { FiDownload } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "reactstrap";
import link from "../../../assets/imges/DeviceTableLink.png";
import Setting from "../../../assets/imges/DeviceTableSetting.png";
import Toggle from "../../../assets/imges/DeviceTableToggle.png";
import {
  deleteDevices,
  getBusinessDevices,
  getBusinessesList,
  seTgetErrorNull,
  updateDevice,
} from "../../../redux/slices/userSlice";
const options = [
  { label: "Option 1", value: "option1" },
  { label: "Option 2", value: "option2" },
  { label: "Option 3", value: "option3" },

  // ... other options ...
];

const DeviceListCard = (props) => {
  const dispatch = useDispatch();
  const { isLoading, getError, businessesList } = useSelector(
    (state) => state.user
  );
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (getError && getError.length > 1) {
      toast.error(getError, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      dispatch(seTgetErrorNull());

    }
  }, [getError]);
  function handleAssign(deviceId, assignedToBusiness) {
    var data = new FormData();
    props.data.id && data.append("device_id", props.data.id);
    assignedToBusiness &&
      data.append("assigned_to_business", assignedToBusiness);
    dispatch(updateDevice(data, handleResponse));
    setShowModal(true);
  }
  function handleResponse(data) {
    if (data?.status) {
      toast.success("Device Assigned Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setShowModal(true);
      dispatch(getBusinessDevices());
      dispatch(getBusinessesList());
    }
  }
  function handleDeleteDevice() {
    var data = {
      device_ids: [props.data?.id],
    };
    dispatch(deleteDevices(data));
  }
  return (
    <>
      <div className="d-flex gap-4 justify-content-between  relative p-3 pt-lg-3 pt-md-3 pt-5 bg-white rounded-[40px] flex-wrap mb-2 border">
        <div className="inter d-flex gap-2 align-items-center whitespace-nowrap w-60	">
          <div className="px-2">
            <input
              type="checkbox"
              className="!accent-[#65558F] !border-[#C8C3C9] !border-[1.5px] scale-150	"
            />
          </div>

          <div className="text-[#343C6A]">
            <h3 className="font-18 ">
              {props.data?.brand || "Time Link Connect"}
            </h3>
            <h3 className="font-16">
              {props.data?.topic_name || "TLC3891000001"}
            </h3>
            <h3 className="font-10">
              {" "}
              {props.data?.assigned_to?.business_name || "Not Yet Assigned"}
            </h3>
            <h3 className="font-10 ">
              Created: {props.data?.created_at || " Aug 26, 2023"}
            </h3>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-between  ps-3 my-2 py-2 whitespace-nowrap	border-l-[1.5px] text-[#343C6A]">
          <div>
            <div className=" form-switch black-switch d-flex text-start align-items-center ps-0">
              {/* <MdToggleOn className='grey' size={28} /> */}
              <img src={Toggle} width={22} height={22} alt="w8" />

              <p className="mb-0 font-12 fw-medium  ms-2">
                {props.data?.status || "Active"}
              </p>
            </div>
            <div className="d-flex align-items-end mt-2 text-start">
              {/* <FaHandHoldingUsd size={28} className='grey' /> */}
              <img src={link} width={22} height={22} alt="w8" />
              <p className="mb-0 font-12 fw-medium  ms-2">
                {props.data?.purchase_type || "Leased"}
              </p>
            </div>
            <div className="d-flex align-items-end mt-2 text-start">
              {/* <AiFillSetting size={28} className='grey' /> */}
              <img src={Setting} width={22} height={22} alt="w8" />

              <p className="mb-0 font-12 fw-medium  ms-2">
                {props.data?.version || "Version 1"}
              </p>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center flex-1">
          <div className="w-3/5 mx-auto">
            <Button
              className="btn-white-borderTwo text-capitalize fw-bold rounded-5 px-3 p-1  font-14 w-full"
              onClick={() => {
                alert(props.data?.id);
              }}
            >
              Detail
            </Button>
            <div>
              {/* {!props.data?.assigned_to && */}
              <Button
                className="btn-white-borderTwo text-capitalize fw-bold rounded-5 px-3 p-1 mt-2  font-14 w-full"
                onClick={() => setShowModal(true)}
              >
                {props.data?.assigned_to_business ? "Reassign" : "Assign"}
              </Button>
            </div>
          </div>
        </div>
        <div className="deviceTableLastTD flex ">
          {props.data?.problems && props.data?.problems?.length > 0 && (
            <button
              className={`${props.data?.problems[props.data?.problems.length - 1]?.priority.toLowerCase().includes('p2')
                ? "bg-orange-600"
                : props.data?.problems[props.data?.problems.length - 1]?.priority.toLowerCase().includes('p1')
                  ? "bg-red-600"
                  : props.data?.problems[props.data?.problems.length - 1]?.priority.toLowerCase().includes('p3')
                    ? "bg-yellow-600"
                    : "bg-green-600"
                } fw-medium text-white !rounded-tr-[40px] !rounded-bl-[40px] p-2 px-5 text-capitalize  font-12 absolute top-0 right-0 w-fit`}
            >
              {props.data?.working_status}
            </button>
          )}
          <div className="d-flex gap-2 align-items-center justify-content-center mt-2">
            <div className=" h-7 w-7 flex items-center justify-center rounded-lg bg-customBlue">
              <FiDownload size={16} color="white" />
            </div>
            <div className="relative">

              <Menu
                menuButton={
                  <MenuButton>
                    <div className=" h-7 w-7 flex items-center justify-center rounded-lg bg-[#718EBF] ">
                      <BsThreeDotsVertical size={16} color="white" />
                    </div>
                  </MenuButton>
                } direction="left"
                align="center" position="anchor" arrow={true}
                shift={12}
                transition
                menuClassName="!min-w-fit"
              >
                <MenuItem>Edit</MenuItem>
                <MenuItem>Duplicate</MenuItem>
                <MenuItem>
                  <div onClick={handleDeleteDevice}>Delete</div>
                </MenuItem>
              </Menu>
            </div>
          </div>
          <div></div>
        </div>
      </div>
      <ToastContainer />
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="lg"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <h4 className="font-25 inter mt-3 w-100 text-dark text-center">
            Assign a business
          </h4>
        </Modal.Header>
        <Modal.Body>
          <div className=" businessModal pb-4">
            <div className="">
              {/* <div className=" focus:outline-none border h-10 px-2 rounded-xl flex items-center bg-white text-navyBlue w-full">
                <input
                  type="text" id="search"
                  list="search-list"
                  placeholder="search"
                  className="flex-1 border-0 focus:outline-none h-full bg-white placeholder:text-navyBlue text-sm"
                />
                <label htmlFor="search">

                  <FaAngleDown />
                </label>
              </div> */}
            </div>
            {businessesList?.approved &&
              businessesList?.approved
                ?.filter(
                  (index) => index.id != props.data?.assigned_to_business
                )
                .map((index) => {
                  return (
                    <>
                      <div className="flex p-2 my-3 items-center">
                        <div className="flex-1 flex items-center gap-1">

                          <img src={index.image} width={66} height={66} className="border rounded-lg" alt="" />
                          <div className="ms-2 mt-2 ">
                            <p className="text-base mb-0 fw-semibold">
                              {index.business_name}
                            </p>
                            <p className="text-sm text-navyBlue mb-0">
                              {index?.devices?.length} devices assigned
                            </p>
                          </div>
                        </div>
                        <button
                          type="button"
                          className="border-navyBlue border-[1.5px] text-navyBlue p-2.5 rounded-full w-32 text-sm"
                          sx={{ minWidth: "100px", maxHeight: "50px" }}
                          onClick={() => {
                            handleAssign(props?.deviceId, index?.id);
                          }}
                        >
                          {isLoading ? (
                            <Spinner size={"sm"} color={"blue"} />
                          ) : (
                            "Assign"
                          )}
                        </button>
                      </div>
                    </>
                  );
                })}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeviceListCard;
