/* eslint-disable eqeqeq */
import React, { useEffect } from "react";
import Header from "../components/Header";
import SideBar from "../components/SideBar";
import { GoTriangleDown } from "react-icons/go";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Checkbox,
} from "@mui/material";
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { FaUsers } from "react-icons/fa6";
import { LuAlarmClock } from "react-icons/lu";
import { BsFileEarmarkSpreadsheetFill } from "react-icons/bs";
import { PieChart } from "react-minimal-pie-chart";
import { IoSearch } from "react-icons/io5";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import entry from "../assets/imges/entry.png";
import progress from "../assets/imges/progress-button.png";
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getBusinessOrders, seTgetErrorNull } from "../redux/slices/userSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getTotalTimeForOrder } from "../helpers/helpers";
import FullScreenLoader from "../components/loaders/FullScreenLoader";
const data = [
  { name: "6am", uv: 0 },
  { name: "7am", uv: 120 },
  { name: "8am", uv: 80 },
  { name: "9am", uv: 100 },
  { name: "10am", uv: 80 },
  { name: "11am", uv: 400 },
  { name: "12am", uv: 170 },
  { name: "1am", uv: 500 },
  { name: "2am", uv: 430 },
  { name: "3am", uv: 300 },
  { name: "4am", uv: 280 },
  { name: "5am", uv: 450 },
];

// const CustomTick = (props) => {
//     const { x, y, payload } = props;
//     return (
//         <text x={x} y={y + 10} fontSize="12" className='poppins fw-medium' textAnchor="middle" fill="#A3AED0">

//             {payload.value}
//         </text>
//     );
// };
const arrowStyle = {
  position: "absolute",
  left: "18%", // Center the arrow
  transform: "translateX(-50%)", // Center the arrow
  top: "100%", // Adjust this value as needed
  width: "15px",
  height: "8px",
  background: "white", // Arrow color
  clipPath: "polygon(0% 0%, 50% 100%, 100% 0%)", // Arrow shape
};
const CustomTooltip = ({ active, payload, avwt, bp }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        {/* <p className="label">{`${label} : ${payload[0].value}`}</p> */}
        {/* You can customize the tooltip content here */}
        <div
          className="custom-tooltip  rounded-3 p-1 px-2 border-0 "
          style={{
            backgroundColor: "white",
            filter: "drop-shadow(6px 12px 35px rgba(0, 0, 0, 0.08))",
          }}
        >
          <div className="tooltip-arrow" style={arrowStyle} />
          <p className="label mb-0  poppins font-12 fw-semibold">
            <span>
              <FaUsers className="blue" />
            </span>{" "}
            Orders:
            <span className="fw-normal">{` ${payload[0].value}`}</span>
          </p>
          <p className="label mb-0  poppins font-12 fw-semibold">
            <span>
              <LuAlarmClock className="blue" />
            </span>{" "}
            Average Wait:
            <span className="fw-normal">{avwt}</span>
          </p>
          <p className="label mb-0  poppins font-12 fw-semibold">
            <span>
              <BsFileEarmarkSpreadsheetFill className="blue" />
            </span>{" "}
            {bp} : <span className="fw-normal">Busiest period</span>
          </p>
        </div>
      </div>
    );
  }

  return null;
};
const NewOrder = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, getError, businessOrders, userDetail, selectedLocation } = useSelector(
    (state) => state.user
  );
  const [timeframe, setTimeframe] = React.useState(false);
  const [client, setClient] = React.useState(false);
  const [status, setStatus] = React.useState(false);
  const [timeframeRadio, setTimeframeRadio] = React.useState("All-time");
  const [clientRadio, setClientRadio] = React.useState("All");
  const [statusRadio, setStatusRadio] = React.useState("All");
  const [user, setUser] = React.useState(false);
  const [userRadio, setUserRadio] = React.useState("New Customer");
  let [mainGraph, setMainGraph] = React.useState(null);
  useEffect(() => {
    if (Object.keys(userDetail).length > 0) {
      if (!userDetail?.phone) {
        navigate("/onboarding/number");
      } else if (!userDetail?.is_phone_verified) {
        navigate("/onboarding/number/verify");
      } else if (!userDetail?.business_name) {
        navigate("/profile/business-name");
      } else if (!userDetail?.abn) {
        navigate("/profile/abn");
      } else if (!userDetail?.image) {
        navigate("/profile/logo");
      } else if (!userDetail?.business_category) {
        navigate("/profile/category");
      } else if (!userDetail?.is_approved) {
        navigate("/onboarding/reviewing");
      } else if (!userDetail?.subscription_information?.stripe_price_id) {
        navigate("/subscription/payment");
      } else if (userDetail?.subscription_information?.stripe_price_id) {
        if (userDetail?.subscription_information?.status == 'holdandrequestpayment') {
          navigate("/subscription/payment");
        }
      }
    }
  }, [userDetail]);

  useEffect(() => {
    if (getError && getError.length > 1) {
      toast.error(getError, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      dispatch(seTgetErrorNull());

    }
  }, [getError]);
  useEffect(() => {
    console.log('selected location on orders screen', selectedLocation)
    if (selectedLocation) {
      dispatch(getBusinessOrders(selectedLocation));
    }
  }, [selectedLocation]);
  useEffect(() => {
    if (businessOrders?.homeScreenData?.graphData) {
      let transformedData = [];

      // Iterate over the original data object
      for (const timeSlot in businessOrders?.homeScreenData?.graphData) {
        if (
          businessOrders?.homeScreenData?.graphData.hasOwnProperty(timeSlot)
        ) {
          // Creating an object with 'title' and 'value' keys
          transformedData.push({
            title: convertTimeRange(timeSlot),
            orders: businessOrders?.homeScreenData?.graphData[timeSlot],
          });
        }
      }
      console.log("main graph data ", transformedData);
      setMainGraph(transformedData);
    }
  }, [businessOrders]);

  function convertTimeRange(inputString) {
    // Split the input string by ' - ' to separate start and end times
    const times = inputString.split(" - ");

    // Extract the start and end times
    const startTime = times[0];
    const endTime = times[1];

    // Extract the hour part and the period indicator (AM or PM)
    const startHour = parseInt(startTime);
    const endHour = parseInt(endTime);

    // Format the start and end times
    const startFormatted = `${startHour % 12 || 12}-${endHour % 12 || 12
      }${endTime.slice(-2)[0].toLowerCase()}`;

    return startFormatted;
  }
  function getTitleWithMaxOrders(data) {
    let maxOrders = -1;
    let maxTitle = "";

    data.forEach((item) => {
      if (item.orders > maxOrders) {
        maxOrders = item.orders;
        maxTitle = item.title;
      }
    });

    return maxOrders > 0 ? maxTitle : false;
  }
  return (
    <>
      <div className="container-fluid  business_admin_main">
        <FullScreenLoader isLoading={isLoading} />
        <div className="row order">
          {/* <!-- ===========SID-BAR======= --> */}
          <SideBar />
          {/* <!-- ========= content body ======= --> */}
          <div className="w-lg-80 px-0 h-screen overflow-y-scroll ">
            <Header
              PageTitle="Orders"
              activeState={"overview"}
              isStarterPlan={[
                'price_1QV39FEhsh9AaJCvODSIlKlZ', 'price_1QV39FEhsh9AaJCvH07TeW0b'
              ].includes(userDetail?.subscription_information?.stripe_price_id)}
            />
            <div className="px-lg-4 px-md-3 px-2">
              <div className="container-fluid p-0 pb-4 mt-4">
                <div className="row justify-content-between new-select-option g-0">
                  <div className="col-8">
                    <div className="row g-2">
                      <div className="col-4 position-relative">
                        <div
                          className=" stats_select rounded-5  d-flex align-items-center white-border "
                          onClick={() => setTimeframe(!timeframe)}
                        >
                          <p className="mb-0 me-1 text-dark-gray fw-medium ">
                            Timeframe:
                          </p>
                          <div className="d-flex align-items-center justify-content-between w-100">
                            {timeframeRadio}
                            <GoTriangleDown />
                          </div>
                        </div>
                        {timeframe && (
                          <div className="position-absolute bg-white p-2 px-3 shadow w-100 top-100 start-0 rounded-4 z-1">
                            <FormControl>
                              <RadioGroup
                                // defaultValue="All-time"
                                onChange={(e) => {
                                  setTimeframeRadio(e.target.value);
                                  setTimeframe(false);
                                }}
                                value={timeframeRadio}
                                name="radio-buttons-time"
                              >
                                <FormControlLabel
                                  value="All-time"
                                  control={
                                    <Radio
                                      sx={{
                                        color: "#1D59F9",
                                        "&.Mui-checked": {
                                          color: "#1D59F9   ",
                                        },
                                      }}
                                    />
                                  }
                                  label="All-time"
                                />
                                <FormControlLabel
                                  value="Once"
                                  control={
                                    <Radio
                                      sx={{
                                        color: "#1D59F9",
                                        "&.Mui-checked": {
                                          color: "#1D59F9   ",
                                        },
                                      }}
                                    />
                                  }
                                  label="One"
                                />
                                <FormControlLabel
                                  value="Monthly"
                                  control={
                                    <Radio
                                      sx={{
                                        color: "#1D59F9",
                                        "&.Mui-checked": {
                                          color: "#1D59F9   ",
                                        },
                                      }}
                                    />
                                  }
                                  label="Monthly"
                                />
                              </RadioGroup>
                            </FormControl>
                          </div>
                        )}
                      </div>
                      <div className="col-4 position-relative">
                        <div
                          className=" stats_select rounded-5  d-flex align-items-center white-border "
                          onClick={() => setClient(!client)}
                        >
                          <p className="mb-0 me-1 text-dark-gray fw-medium ">
                            Client:
                          </p>
                          <div className="d-flex align-items-center justify-content-between w-100">
                            {clientRadio}
                            <GoTriangleDown />
                          </div>
                        </div>
                        {client && (
                          <div className="position-absolute bg-white p-2 px-3 shadow w-100 top-100 start-0 rounded-4 z-1">
                            <FormControl>
                              <RadioGroup
                                // defaultValue="All-time"
                                onChange={(e) => {
                                  setClientRadio(e.target.value);
                                  setClient(false);
                                }}
                                value={clientRadio}
                                name="radio-buttons-client"
                              >
                                <FormControlLabel
                                  value="All"
                                  control={
                                    <Radio
                                      sx={{
                                        color: "#1D59F9",
                                        "&.Mui-checked": {
                                          color: "#1D59F9   ",
                                        },
                                      }}
                                    />
                                  }
                                  label="All"
                                />
                                <FormControlLabel
                                  value="Once"
                                  control={
                                    <Radio
                                      sx={{
                                        color: "#1D59F9",
                                        "&.Mui-checked": {
                                          color: "#1D59F9   ",
                                        },
                                      }}
                                    />
                                  }
                                  label="One"
                                />
                                <FormControlLabel
                                  value="Monthly"
                                  control={
                                    <Radio
                                      sx={{
                                        color: "#1D59F9",
                                        "&.Mui-checked": {
                                          color: "#1D59F9   ",
                                        },
                                      }}
                                    />
                                  }
                                  label="Monthly"
                                />
                              </RadioGroup>
                            </FormControl>
                          </div>
                        )}
                      </div>
                      <div className="col-4 position-relative">
                        <div
                          className=" stats_select rounded-5  d-flex align-items-center white-border "
                          onClick={() => setStatus(!status)}
                        >
                          <p className="mb-0 me-1 text-dark-gray fw-medium ">
                            Status:
                          </p>
                          <div className="d-flex align-items-center justify-content-between w-100">
                            {statusRadio}
                            <GoTriangleDown />
                          </div>
                        </div>
                        {status && (
                          <div className="position-absolute bg-white p-2 px-3 shadow w-100 top-100 start-0 rounded-4 z-1">
                            <FormControl>
                              <RadioGroup
                                // defaultValue="All-time"
                                onChange={(e) => {
                                  setStatusRadio(e.target.value);
                                  setStatus(false);
                                }}
                                value={statusRadio}
                                name="radio-buttons-status"
                              >
                                <FormControlLabel
                                  value="All"
                                  control={
                                    <Radio
                                      sx={{
                                        color: "#1D59F9",
                                        "&.Mui-checked": {
                                          color: "#1D59F9   ",
                                        },
                                      }}
                                    />
                                  }
                                  label="All"
                                />
                                <FormControlLabel
                                  value="Once"
                                  control={
                                    <Radio
                                      sx={{
                                        color: "#1D59F9",
                                        "&.Mui-checked": {
                                          color: "#1D59F9   ",
                                        },
                                      }}
                                    />
                                  }
                                  label="One"
                                />
                                <FormControlLabel
                                  value="Monthly"
                                  control={
                                    <Radio
                                      sx={{
                                        color: "#1D59F9",
                                        "&.Mui-checked": {
                                          color: "#1D59F9   ",
                                        },
                                      }}
                                    />
                                  }
                                  label="Monthly"
                                />
                              </RadioGroup>
                            </FormControl>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                </div>
                <div className="row g-2 mt-3">
                  <div className="col-6 new-order-linechart">
                    <div className="white-border p-3 rounded-4">
                      <div
                        className="pb-3 "
                        style={{ borderBottom: "1.5px solid #E4E5E7" }}
                      >
                        <h6 className="font-14  mb-0 ">Statistics</h6>
                      </div>
                      {mainGraph && (
                        <LineChart
                          width={500}
                          height={220}
                          data={mainGraph}
                          margin={{
                            top: 10,
                            right: 15,
                            left: -28,
                            bottom: 0,
                          }}
                          axisLine={false}
                        >
                          <defs>
                            <linearGradient
                              id="linear-gradient"
                              x1="100%"
                              y1="0%"
                              x2="0%"
                              y2="100%"
                            >
                              <stop offset="13.44%" stop-color="#1B59F8" />
                              <stop
                                offset="104%"
                                stop-color="rgba(27, 89, 248, 0)"
                              />
                            </linearGradient>
                          </defs>
                          <CartesianGrid stroke={false} />
                          <XAxis
                            dataKey="title"
                            tick={{
                              fontFamily: "Inter,sans",
                              fontWeight: "500",
                              fill: "#838383",
                              fontSize: 9,
                            }}
                            interval={0}
                            axisLine={false}
                          />
                          <YAxis
                            axisLine={false}
                            tick={{
                              fontFamily: "Inter,sans",
                              fontWeight: "500",
                              fill: "#838383",
                              fontSize: 9,
                            }}
                          />
                          {/* <Tooltip /> */}
                          <Tooltip
                            content={
                              <CustomTooltip
                                avwt={
                                  businessOrders?.homeScreenData
                                    ?.timeBasedaverageWaitTime
                                }
                                bp={
                                  mainGraph && getTitleWithMaxOrders(mainGraph)
                                    ? getTitleWithMaxOrders(mainGraph) + "m"
                                    : "N/A"
                                }
                              />
                            }
                          />

                          <Line
                            connectNulls
                            type="monotone"
                            strokeWidth={6}
                            dataKey="orders"
                            stroke="url(#linear-gradient)"
                            dot={false}
                            border
                          />
                        </LineChart>
                      )}
                    </div>
                  </div>
                  <div className="col-4  ">
                    <div className="white-border  rounded-4 p-2">
                      <div className="container-fluid h-100">
                        <div className="row h-100">
                          <div className="col-6">
                            <div className="d-flex flex-column h-100">
                              <div className="flex-grow-1">
                                <p className="font-14 fw-medium text-dark-gray">
                                  Open Rate
                                </p>
                                <h4 className="fw-bold">
                                  {businessOrders?.homeScreenData
                                    ?.timeBasedOrders.length == 0
                                    ? 0
                                    : ((businessOrders?.homeScreenData
                                      ?.timeBasedopenOrders /
                                      businessOrders?.homeScreenData
                                        ?.timeBasedOrders.length) *
                                      100).toFixed(2)}
                                  %
                                </h4>
                              </div>
                              <br />
                              <br />
                              <div>
                                <div
                                  className=""
                                  style={{
                                    display: "flex",
                                    alignItems: "baseline",
                                    whiteSpace: "nowrap",
                                    marginTop: "3px",
                                  }}
                                >
                                  <div
                                    className="rounded-1 "
                                    style={{
                                      width: "18px",
                                      height: "10px",
                                      backgroundColor: "#EBEBEB",
                                      marginRight: "5px",
                                    }}
                                  ></div>
                                  <div>
                                    <p className="mb-0 font-13 fw-bold">
                                      {" "}
                                      {
                                        businessOrders?.homeScreenData
                                          ?.timeBasedopenOrders
                                      }{" "}
                                      <span className="fw-normal">
                                        {"Open"}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                <div
                                  className=""
                                  style={{
                                    display: "flex",
                                    alignItems: "baseline",
                                    whiteSpace: "nowrap",
                                    marginTop: "3px",
                                  }}
                                >
                                  <div
                                    className="rounded-1 "
                                    style={{
                                      width: "18px",
                                      height: "10px",
                                      backgroundColor: "#DDE6FE",
                                      marginRight: "5px",
                                    }}
                                  ></div>
                                  <div>
                                    <p className="mb-0 font-13 fw-bold">
                                      {" "}
                                      {
                                        businessOrders?.homeScreenData
                                          ?.timeBasedpendingdOrders
                                      }{" "}
                                      <span className="fw-normal">
                                        Pending Pickup
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                <div
                                  className=""
                                  style={{
                                    display: "flex",
                                    alignItems: "baseline",
                                    whiteSpace: "nowrap",
                                    marginTop: "3px",
                                  }}
                                >
                                  <div
                                    className="rounded-1 "
                                    style={{
                                      width: "18px",
                                      height: "10px",
                                      backgroundColor: "#2B65F8",
                                      marginRight: "5px",
                                    }}
                                  ></div>
                                  <div>
                                    <p className="mb-0 font-13 fw-bold">
                                      {" "}
                                      {
                                        businessOrders?.homeScreenData
                                          ?.timeBasedcompletedOrders
                                      }{" "}
                                      <span className="fw-normal">
                                        Complete
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-6">
                            <PieChart
                              data={[
                                {
                                  title:
                                    businessOrders?.homeScreenData
                                      ?.timeBasedopenOrders,
                                  reach: "Open",
                                  value:
                                    businessOrders?.homeScreenData
                                      ?.timeBasedopenOrders,
                                  color: "#EBEBEB",
                                },
                                {
                                  title:
                                    businessOrders?.homeScreenData
                                      ?.timeBasedpendingdOrders,
                                  reach: "Pending Pickup",
                                  value:
                                    businessOrders?.homeScreenData
                                      ?.timeBasedpendingdOrders,
                                  color: "#DDE6FE",
                                },
                                {
                                  title:
                                    businessOrders?.homeScreenData
                                      ?.timeBasedcompletedOrders,
                                  reach: "Complete",
                                  value:
                                    businessOrders?.homeScreenData
                                      ?.timeBasedcompletedOrders,
                                  color: "#2B65F8",
                                },
                              ]}
                              lineWidth={40}
                              paddingAngle={5}
                            // rounded={1}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-2 d-flex flex-column justify-content-between ">
                    <div className="white-border  rounded-4 p-3">
                      <p className="font-14 fw-medium  text-dark-gray mb-1">
                        Total Orders
                      </p>
                      <h4 className="fw-bold mb-0">
                        {businessOrders?.homeScreenData?.timeBasedOrders.length}
                      </h4>
                    </div>
                    <div className="white-border  rounded-4 p-3">
                      <p className="font-14 fw-medium  text-dark-gray mb-1">
                        Average wait-time
                      </p>
                      <h4 className="fw-bold mb-0">
                        {
                          businessOrders?.homeScreenData
                            ?.timeBasedaverageWaitTime
                        }
                      </h4>
                    </div>
                    <div className="white-border  rounded-4 p-3">
                      <p className="font-14 fw-medium  text-dark-gray mb-1">
                        Busiest period
                      </p>
                      <h4 className="fw-bold mb-0">
                        {mainGraph && getTitleWithMaxOrders(mainGraph)
                          ? getTitleWithMaxOrders(mainGraph) + "m"
                          : "N/A"}
                      </h4>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className=" mt-3 white-border rounded-4 p-3">
                      <div
                        className="d-flex pb-3 align-items-center justify-content-between "
                        style={{
                          borderBottom: "2px solid rgba(244, 245, 247, 1)",
                        }}
                      >
                        <div>
                          <h6 className="font-18 fw-semibold mb-0 ">
                            Todays’ Orders
                            <span className="ms-4 text-gray">
                              {
                                businessOrders?.homeScreenData?.timeBasedOrders
                                  .length
                              }
                            </span>
                          </h6>
                        </div>
                        <img src={progress} width={35} height={35} alt="w8" />
                      </div>
                      <div className="hide-scrollbar custom-table inter overflow-x-scroll">
                        {/* =========table header ======== */}
                        <div className="d-flex mt-3 justify-content-between  w-100 ">
                          <div
                            className="  d-flex flex-shrink-0 align-items-center justify-content-center "
                            style={{ width: 200 }}
                          >
                            <div className="d-flex align-items-center">
                              <IoSearch className="text-dark-gray" size={22} />
                              <input
                                type="search"
                                className="form-control ps-2 border-0 font-14 fw-medium py-1"
                                placeholder="Search"
                              />
                            </div>
                          </div>
                          <div className="table-cell  d-flex align-items-center justify-content-center position-relative ">
                            <span
                              role="button"
                              className="py-2 d-flex justify-content-between align-items-center rounded-3 font-12 fw-semibold   text-dark-gray  table-header-badges"
                              onClick={() => setUser(!user)}
                              style={{ width: "85%", paddingInline: "12px" }}
                            >
                              {userRadio}
                              {user ? (
                                <FaAngleDown className="ms-2" />
                              ) : (
                                <FaAngleUp className="ms-2" />
                              )}
                            </span>{" "}
                            <span className="ms-2">
                              <img src={entry} width="5" height="10" alt="w8" />
                            </span>
                            {user && (
                              <div
                                className="position-absolute bg-white p-2 px-2 shadow  top-100 end-0 rounded-4 z-1"
                                style={{ width: "110%" }}
                              >
                                <FormControl>
                                  <RadioGroup
                                    // defaultValue="All-time"
                                    onChange={(e) => {
                                      setUserRadio(e.target.value);
                                      setUser(false);
                                    }}
                                    value={userRadio}
                                    name="radio-buttons-user"
                                  >
                                    <FormControlLabel
                                      value="All"
                                      control={
                                        <Radio
                                          sx={{
                                            color: "#1D59F9",
                                            "&.Mui-checked": {
                                              color: "#1D59F9   ",
                                            },
                                          }}
                                        />
                                      }
                                      label="All"
                                    />
                                    <FormControlLabel
                                      value="New Customer"
                                      control={
                                        <Radio
                                          sx={{
                                            color: "#1D59F9",
                                            "&.Mui-checked": {
                                              color: "#1D59F9   ",
                                            },
                                          }}
                                        />
                                      }
                                      label="New Customer"
                                    />
                                    <FormControlLabel
                                      value="Repeat Customer"
                                      control={
                                        <Radio
                                          sx={{
                                            color: "#1D59F9",
                                            "&.Mui-checked": {
                                              color: "#1D59F9   ",
                                            },
                                          }}
                                        />
                                      }
                                      label="Repeat Customer"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </div>
                            )}
                          </div>
                          <div className="table-cell  d-flex align-items-center justify-content-center">
                            <span
                              className="p-2 rounded-3 font-12 fw-semibold  d-flex justify-content-between align-items-center text-dark-gray px-4 table-header-badges"
                              style={{ width: "70%" }}
                            >
                              Start <FaAngleDown />
                            </span>{" "}
                            <span className="ms-2">
                              <img src={entry} width="5" height="10" alt="w8" />
                            </span>
                          </div>
                          <div className="table-cell  d-flex align-items-center justify-content-center">
                            <span
                              className="p-2  text-center rounded-3 font-12 fw-semibold   text-dark-gray px-2 table-header-badges"
                              style={{ width: "70%" }}
                            >
                              End
                            </span>{" "}
                            <span className="ms-2">
                              <img src={entry} width="5" height="10" alt="w8" />
                            </span>
                          </div>
                          <div className="table-cell  d-flex align-items-center justify-content-center">
                            <span
                              className="p-2 text-center rounded-3 font-12 fw-semibold   text-dark-gray px-4 table-header-badges"
                              style={{ width: "70%" }}
                            >
                              Total Time
                            </span>{" "}
                            <span className="ms-2">
                              <img src={entry} width="5" height="10" alt="w8" />
                            </span>
                          </div>
                          <div className="table-cell  d-flex align-items-center justify-content-center">
                            <span
                              className="p-2 text-center rounded-3 font-12 fw-semibold   text-dark-gray px-2 table-header-badges"
                              style={{ width: "70%" }}
                            >
                              Status
                            </span>{" "}
                            <span className="ms-2">
                              <img src={entry} width="5" height="10" alt="w8" />
                            </span>
                          </div>
                        </div>
                        {/* ============ table body ======== */}
                        {businessOrders?.homeScreenData?.timeBasedOrders.map(
                          (value, index) => {
                            return (
                              <div className="d-flex mt-3 justify-content-between  w-100 ">
                                <div className="table-cell-1 flex-shrink-0 d-flex align-items-center ">
                                  <Checkbox
                                    icon={
                                      <MdRadioButtonUnchecked
                                        color="rgba(0, 0, 0, 0.3)
"
                                        size={20}
                                      />
                                    }
                                    checkedIcon={
                                      <MdRadioButtonChecked size={20} />
                                    }
                                  />
                                  <div>
                                    <p className="mb-0 font-14 fw-semibold ">
                                      {value.order_id}
                                    </p>
                                    <p className="mb-0 font-14 text-black-50 ">
                                      Repeat Customer
                                    </p>
                                  </div>
                                </div>
                                <div className="table-cell  text-center  d-flex align-items-center justify-content-center">
                                  {value.isRepeating ? "Yes" : "No"}
                                </div>
                                <div className="table-cell   text-center  d-flex align-items-center justify-content-center">
                                  <div>
                                    <p className="mb-0 font-14 fw-medium  text-black-50 ">
                                      {value.start_time}
                                    </p>
                                    {/* <p className='mb-0 font-14 fw-medium  text-black-50 '>12:15pm</p> */}
                                  </div>
                                </div>
                                <div className="table-cell  text-center  d-flex align-items-center justify-content-center">
                                  <div>
                                    <p className="mb-0 font-14 fw-medium  text-black-50 ">
                                      {value.end_time}
                                    </p>
                                    {/* <p className='mb-0 font-14 fw-medium  text-black-50 '>12:15pm</p> */}
                                  </div>
                                </div>
                                <div className="table-cell  text-center  d-flex align-items-center justify-content-center ">
                                  <p className="mb-0 font-14 fw-medium  text-black-50 ">
                                    {getTotalTimeForOrder(
                                      value.start_time,
                                      value.end_time
                                    ) == "N/A"
                                      ? "00:00:00"
                                      : getTotalTimeForOrder(
                                        value.start_time,
                                        value.end_time
                                      )}
                                  </p>
                                </div>
                                <div className="table-cell  text-center  d-flex align-items-center justify-content-center ">
                                  <p className="mb-0 font-14 fw-medium  text-black-50 ">
                                    {value.status}
                                  </p>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default NewOrder;
