import React, { useState, useEffect, useRef } from 'react';
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import { Button, FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { GoTriangleDown } from "react-icons/go";
import { Input, Spinner } from "reactstrap";
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal } from "react-bootstrap";
import { GrFormClose } from "react-icons/gr";
import { FaPlus } from 'react-icons/fa';
import { createBusinessLocations, deleteBusinessLocation, getBusinessLocations, getSquareRedirectUrl, updateBusinessLocations } from '../../redux/slices/userSlice';
import { Refresh } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { FiUploadCloud } from 'react-icons/fi';
import { IoSettingsOutline } from 'react-icons/io5';
import upload from "../../assets/imges/upload.png";
import { FiUpload, FiZoomIn, FiZoomOut } from "react-icons/fi";
import {
    FaArrowRightLong,
    FaArrowRotateLeft,
    FaArrowRotateRight,
    FaCrown,
} from "react-icons/fa6";
import AvatarEditor from "react-avatar-editor";


const Location = () => {
    const dispatch = useDispatch();
    const { isLoading, getError, businessLocations } = useSelector((state) => state.user)

    const [status, setStatus] = useState(false);
    const [statusRadio, setStatusRadio] = useState('Active');
    const [showForm, setShowForm] = useState(false);
    const [isConnected, setIsConnected] = useState(false);

    //   ---------- image crop ----------
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [editImgModal, setEditImgModal] = useState(false);
    const [image, setImage] = useState("");
    const [scale, setScale] = useState(1);
    const [rotate, setRotate] = useState(0);
    const [cnvsImage, setCnvsImage] = useState("");

    // ---- logo crop function
    const [fileLogo, setFileLogo] = useState(null);
    // const [fileNameLogo, setFileNameLogo] = useState(null);
    const [editImgModalLogo, setEditImgModalLogo] = useState(false);
    const [imageLogo, setImageLogo] = useState("");
    const [scaleLogo, setScaleLogo] = useState(1);
    const [rotateLogo, setRotateLogo] = useState(0);
    const [cnvsImageLogo, setCnvsImageLogo] = useState("");
    const [formData, setFormData] = useState({
        name: '',
        nick_name: '',
        description: '',
        address: '',
        city: '',
        postal_code: '',
        contact_email: '',
        contact_phone: ''
    });

    // Mock data - replace with actual API call
    useEffect(() => {
        dispatch(getBusinessLocations());
    }, []);

    const validateForm = () => {
        // Required fields validation
        const requiredFields = {
            name: 'Location Name',
            address: 'Address',
            city: 'City',
            postal_code: 'Postal Code',
            contact_email: 'Contact Email',
            contact_phone: 'Contact Phone'
        };

        for (const [field, label] of Object.entries(requiredFields)) {
            if (!formData[field]?.trim()) {
                toast.error(`${label} is required!`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                return false;
            }
        }

        // Email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.contact_email)) {
            toast.error('Please enter a valid email address!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return false;
        }

        // Phone validation
        const phoneRegex = /^\+?[\d\s-]{10,}$/;
        if (!phoneRegex.test(formData.contact_phone)) {
            toast.error('Please enter a valid phone number!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return false;
        }

        return true;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        const formDataObj = new FormData();
        formDataObj.append('name', formData.name);
        formDataObj.append('nick_name', formData.nick_name);
        formDataObj.append('description', formData.description);
        formDataObj.append('address', formData.address);
        formDataObj.append('city', formData.city);
        formDataObj.append('postal_code', formData.postal_code);
        formDataObj.append('contact_email', formData.contact_email);
        formDataObj.append('contact_phone', formData.contact_phone);
        if (fileLogo) {
            console.log("🚀 ~ handleSubmitBusinessDetails ~ fileLogo:", fileLogo);
            formDataObj.append("images", fileLogo);
        }
        if (file) {
            console.log("🚀 ~ handleSubmitBusinessDetails ~ file:", file);
            formDataObj.append("cover_images", file);
        }

        dispatch(createBusinessLocations(formDataObj, () => {
            setShowForm(false);
            setFormData({
                name: '',
                nick_name: '',
                description: '',
                address: '',
                city: '',
                postal_code: '',
                contact_email: '',
                contact_phone: ''
            });
            dispatch(getBusinessLocations());
        }));
    };

    function handleConnectSquareLocation(locationId, square_location_id) {
        dispatch(updateBusinessLocations({
            square_location_id
        }, locationId))
    }
    function handleConnectDevice(locationId, device_id) {
        dispatch(updateBusinessLocations({
            device_id
        }, locationId))
    }

    const previewRef = useRef();
    const previewRefLogo = useRef();

    const handlePreview = () => {
        if (previewRef.current) {
            const cnvs = previewRef.current.getImage();
            cnvs.toBlob((blob) => {
                setFile(blob);

                const previousImg = URL.createObjectURL(blob);
                setCnvsImage(previousImg);
            });
            setEditImgModal(false);
            setFileName(image?.name);
            setImage("");
            console.log(cnvsImage, "-----final");
        }
    };
    const handlePreviewLogo = () => {
        if (previewRefLogo.current) {
            const cnvs = previewRefLogo.current.getImage();
            // console.log("canvas get image ", cnvs);
            cnvs.toBlob((blob) => {
                setFileLogo(blob);

                const previousImg = URL.createObjectURL(blob);
                setCnvsImageLogo(previousImg);
            });
            setEditImgModalLogo(false);
            // setFileNameLogo(image?.name);
            setImageLogo("");
        }
    };
    return (
        <section className='business_admin_main'>
            <div className="container-fluid Device-main">
                <div className="row order">
                    {/* <!-- ===========SID-BAR======= --> */}
                    <SideBar />

                    {/* <!-- ========= content body ======= --> */}
                    <div className="w-lg-80 px-0 h-screen overflow-y-scroll">
                        {/* <!-- ===========HEADER========== --> */}
                        <Header PageTitle="Locations" />

                        <div className="px-lg-4 px-md-3 px-2">
                            <div className="container-fluid mt-3 px-0">
                                <div className='row justify-content-between new-select-option g-0'>
                                    <div className="col-3 position-relative">
                                        <div className='stats_select rounded-5 d-flex align-items-center white-border'
                                            onClick={() => setStatus(!status)}>
                                            <p className='mb-0 me-1 text-dark-gray fw-medium'>Status:</p>
                                            <div className='d-flex align-items-center justify-content-between w-100'>
                                                {statusRadio}
                                                <GoTriangleDown />
                                            </div>
                                        </div>
                                        {status && (
                                            <div className='position-absolute bg-white p-2 px-3 shadow w-100 top-100 start-0 rounded-4 z-1'>
                                                <FormControl>
                                                    <RadioGroup
                                                        onChange={(e) => {
                                                            setStatusRadio(e.target.value);
                                                            setStatus(false);
                                                        }}
                                                        value={statusRadio}
                                                        name="radio-buttons-status"
                                                    >
                                                        <FormControlLabel value="Active" control={<Radio
                                                            sx={{
                                                                color: '#1D59F9',
                                                                '&.Mui-checked': {
                                                                    color: '#1D59F9',
                                                                },
                                                            }}
                                                        />} label="Active" />
                                                        <FormControlLabel value="Inactive" control={<Radio
                                                            sx={{
                                                                color: '#1D59F9',
                                                                '&.Mui-checked': {
                                                                    color: '#1D59F9',
                                                                },
                                                            }}
                                                        />} label="Inactive" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </div>
                                        )}
                                    </div>

                                    <div className="col-auto flex flex-row gap-1">
                                        <button
                                            className="bg-customBlue flex gap-2 items-center text-white rounded-full py-2 p-3"
                                            onClick={() => dispatch(getBusinessLocations())}
                                        >
                                            <Refresh size={18} />
                                            Refresh
                                        </button>

                                        <button
                                            className="bg-customBlue flex gap-2 items-center text-white rounded-full py-2 p-3"
                                            onClick={() => setShowForm(true)}
                                        >
                                            <FaPlus size={18} />
                                            Add Location
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="table-scroll white-border p-3 rounded-4 mt-4">
                                <div style={{ borderBottom: "1px solid #E4E5E7" }}>
                                    <h6 className="font-14">Location list</h6>
                                </div>
                                <div>
                                    {isLoading ? (
                                        <div className="row justify-content-center not-active-Campaigns rounded-3 text-center mt-3">
                                            <div className="col-12">
                                                <div className="Campaigns p-4 rounded-3">
                                                    <Spinner color='dark' />
                                                </div>
                                            </div>
                                        </div>
                                    ) : businessLocations?.locations?.length < 1 ? (
                                        <div className="row justify-content-center not-active-Campaigns rounded-3 text-center mt-3">
                                            <div className="col-12">
                                                <div className="Campaigns p-4 rounded-3">
                                                    <h5 className='fw-bold'>No Locations Found</h5>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        businessLocations?.locations?.map((location) => (
                                            <div key={location.id} className="d-flex flex-wrap device-card-data justify-content-between p-2 bg-white rounded-4 mt-2">
                                                <div className="inter d-flex align-items-center" style={{ whiteSpace: 'nowrap' }}>
                                                    <div className="px-3">
                                                        <Input type="checkbox" style={{ transform: 'scale(1.3)', borderColor: '#C8C3C9', borderWidth: '1.5' }} />
                                                    </div>
                                                    <div>
                                                        <p className="font-18 mb-2" style={{ color: 'rgba(83, 83, 83, 1)' }}>{location.name} {'(' + location.nick_name + ')'}</p>
                                                        <h5 className="font-10" style={{ color: 'rgba(83, 83, 83, 1)' }}>{location.address}</h5>
                                                    </div>
                                                </div>

                                                <div className="d-flex flex-column gap-1 align-items-center justify-content-center">
                                                    {location.status === 'active' && (
                                                        <Button className='btn-fill-green px-2 py-1 rounded-5 font-15 fw-semibold text-capitalize'
                                                            style={{ whiteSpace: 'nowrap', minWidth: '100px' }}>
                                                            Active
                                                        </Button>
                                                    )}
                                                </div>

                                                <div className="d-flex align-items-center linkSetting ps-3 my-2 py-2" style={{ whiteSpace: 'nowrap' }}>
                                                    <div>
                                                        <div className="d-flex align-items-end mt-2 text-start">
                                                            <p className="mb-0 font-10 fw-medium ms-2" style={{ color: 'rgba(170, 170, 170, 1)' }}>
                                                                {location.city}, {location.city} {location.postal_code}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="d-flex align-items-center">
                                                    <div className="d-flex flex-column gap-1">
                                                        <Button className='btn-white-borderRed text-capitalize fw-bold rounded-5 p-2 font-14'
                                                            sx={{ minWidth: '100px' }}
                                                            style={{ borderColor: 'red' }}
                                                            onClick={() => dispatch(deleteBusinessLocation(location.id))}
                                                        >
                                                            Delete
                                                        </Button>
                                                    </div>
                                                </div>

                                                <div className='w-100 px-5 mt-4  text-sm flex flex-row gap-4'>
                                                    <div className='w-1/3'>

                                                        <h5>
                                                            Connect to Square
                                                        </h5>
                                                        <div className="d-flex flex-column w-100  text-xs">
                                                            <p>
                                                                Select a Square Location
                                                            </p>
                                                            <select
                                                                className="text-xs"
                                                                style={{
                                                                    borderStyle: 'solid',
                                                                    borderColor: '#C8C3C9',
                                                                    borderWidth: 1,
                                                                    padding: 10,
                                                                    borderRadius: 25,
                                                                }}
                                                                onChange={(e) => {
                                                                    if (e.target.value != 0) {
                                                                        handleConnectSquareLocation(location.id, e.target.value)
                                                                    }
                                                                }}
                                                            >
                                                                <option value="0">Select a Square Location to Link</option>

                                                                {/* Render Primary Square Locations */}
                                                                {businessLocations?.squareLocations?.map((_location) => (
                                                                    <option selected={location.square_location_id == _location.id} key={`primary-${_location?.id}`} className="text-xs" value={_location?.id}>
                                                                        {_location?.name}
                                                                    </option>
                                                                ))}

                                                                {/* Separator */}
                                                                <option disabled className="text-xs" value="separator">
                                                                    --- Secondary Locations ---
                                                                </option>

                                                                {/* Render Secondary Square Locations */}
                                                                {businessLocations?.secondarySquareLocations?.map((_location) => (
                                                                    <option selected={location.square_location_id == _location.id} key={`secondary-${_location?.id}`} className="text-xs" value={_location?.id}>
                                                                        {_location?.name}
                                                                    </option>
                                                                ))}
                                                            </select>

                                                            <h6 className='my-2 mx-auto'>
                                                                OR
                                                            </h6>
                                                            <div className="flex gap-3 items-center justify-center">
                                                                <button className={`px-3 py-1.5 rounded-full shadow-[0_9px_28px_8px_#0000000D]
                                                                         text-xs flex gap-1 items-center border-[1.3px] ${isConnected ? 'bg-[#1B59F8] text-white  border-black' : 'bg-white  border-gray-300 text-blue-700'}`}
                                                                    onClick={() => {
                                                                        dispatch(getSquareRedirectUrl(function handleResponse(url) {
                                                                            window.location.replace(url);
                                                                        }, true))
                                                                    }}
                                                                >
                                                                    {isConnected ? 'Connected' : 'Connect a separate account'} <span> {!isConnected ? <FiUploadCloud size={15} /> : <IoSettingsOutline size={15} />}</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='w-1/3'>
                                                        <h5>
                                                            Connect to QR Buzzer
                                                        </h5>
                                                        <div className="d-flex flex-column w-100  text-xs">
                                                            <p>
                                                                Select Device
                                                            </p>
                                                            <select
                                                                className="text-xs"
                                                                style={{
                                                                    borderStyle: 'solid',
                                                                    borderColor: '#C8C3C9',
                                                                    borderWidth: 1,
                                                                    padding: 10,
                                                                    borderRadius: 25,
                                                                }}

                                                                onChange={(e) => {
                                                                    if (e.target.value != 0) {
                                                                        handleConnectDevice(location.id, e.target.value)
                                                                    }
                                                                }}
                                                            >
                                                                <option value="0">Select a device to link </option>

                                                                {/* Render Primary Square Locations */}
                                                                {businessLocations?.devices?.map((device) => (
                                                                    <option selected={location.device_id == device.id} key={`primary-${device?.id}`} className="text-xs" value={device?.id}>
                                                                        {device?.serial_number}
                                                                    </option>
                                                                ))}


                                                            </select>


                                                        </div>
                                                    </div>
                                                    <div className='w-1/3 flex flex-row p-1 gap-4'>
                                                        <img src={location.image} alt="location logo" className='w-1/2 h-full rounded-2xl' />
                                                        <img src={location.cover_image} alt="location cover " className='w-1/2 h-full rounded-2xl' />
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
            <Modal
                size="lg"
                show={showForm}
                onHide={() => setShowForm(false)}
                className="ps-0"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="font-lg inter">Create Location</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="px-2 pb-3">
                        <form onSubmit={handleSubmit}>
                            <div className="container-fluid">
                                <div className="grid gap-4 lg:grid-cols-2 grid-cols-1">
                                    <div className="col-lg-2 col-12">
                                        Logo
                                        <div className="relative inline-block">
                                            <img
                                                src={
                                                    cnvsImageLogo
                                                        ? cnvsImageLogo // Use profileImg if available
                                                        :  // Fallback to userDetail.image if profileImg is not available
                                                        "https://via.placeholder.com/100x100.png" // Final fallback to placeholder image
                                                }
                                                alt={"Profile"}
                                                className="w-32 h-32 !rounded-2xl shadow object-cover"
                                            />

                                            <div
                                                onClick={() => setEditImgModalLogo(true)}
                                                className="absolute bottom-0 right-0 cursor-pointer"
                                            >
                                                <svg
                                                    width="40"
                                                    height="40"
                                                    viewBox="0 0 40 40"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <circle
                                                        cx="20"
                                                        cy="20"
                                                        r="19"
                                                        fill="#F196FD"
                                                        stroke="white"
                                                        stroke-width="2"
                                                    />
                                                    <g clip-path="url(#clip0_6084_4534)">
                                                        <path
                                                            d="M29.105 15.3959L27.4843 17.0166C27.3191 17.1818 27.0519 17.1818 26.8867 17.0166L22.9843 13.1143C22.8191 12.949 22.8191 12.6818 22.9843 12.5166L24.605 10.8959C25.2624 10.2385 26.3312 10.2385 26.9921 10.8959L29.105 13.0088C29.766 13.6662 29.766 14.735 29.105 15.3959ZM21.5921 13.9088L12.3601 23.1408L11.6148 27.4123C11.5128 27.9889 12.0156 28.4881 12.5921 28.3897L16.8636 27.6408L26.0957 18.4088C26.2609 18.2436 26.2609 17.9764 26.0957 17.8111L22.1933 13.9088C22.0246 13.7436 21.7574 13.7436 21.5921 13.9088ZM15.9636 22.3498C15.7703 22.1564 15.7703 21.8471 15.9636 21.6537L21.3777 16.2396C21.571 16.0463 21.8804 16.0463 22.0738 16.2396C22.2671 16.433 22.2671 16.7424 22.0738 16.9357L16.6597 22.3498C16.4664 22.5432 16.157 22.5432 15.9636 22.3498ZM14.6945 25.3064H16.382V26.5826L14.1144 26.9799L13.021 25.8865L13.4183 23.6189H14.6945V25.3064Z"
                                                            fill="white"
                                                        />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_6084_4534">
                                                            <rect
                                                                width="18"
                                                                height="18"
                                                                fill="white"
                                                                transform="translate(11.6016 10.3999)"
                                                            />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                            {/* <input
                                type="file"
                                className="hidden"
                                onChange={(e) =>
                                  setProfileImg(e.target.files[0])
                                }
                                id="profile-img"
                                accept=".jpg, .jpeg, .png"
                              /> */}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12 flex flex-col  mt-lg-0 mt-3 ">
                                        Cover Image
                                        {fileName || cnvsImage ? (
                                            <div className="border w-full h-28 rounded-xl relative">
                                                <img
                                                    src={cnvsImage || fileName}
                                                    alt=""
                                                    className="w-full h-full object-cover rounded-xl"
                                                />
                                                <div
                                                    onClick={() => setEditImgModal(true)}
                                                    className="absolute -bottom-2 -right-2 cursor-pointer"
                                                >
                                                    <svg
                                                        width="40"
                                                        height="40"
                                                        viewBox="0 0 40 40"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <circle
                                                            cx="20"
                                                            cy="20"
                                                            r="19"
                                                            fill="#F196FD"
                                                            stroke="white"
                                                            stroke-width="2"
                                                        />
                                                        <g clip-path="url(#clip0_6084_4534)">
                                                            <path
                                                                d="M29.105 15.3959L27.4843 17.0166C27.3191 17.1818 27.0519 17.1818 26.8867 17.0166L22.9843 13.1143C22.8191 12.949 22.8191 12.6818 22.9843 12.5166L24.605 10.8959C25.2624 10.2385 26.3312 10.2385 26.9921 10.8959L29.105 13.0088C29.766 13.6662 29.766 14.735 29.105 15.3959ZM21.5921 13.9088L12.3601 23.1408L11.6148 27.4123C11.5128 27.9889 12.0156 28.4881 12.5921 28.3897L16.8636 27.6408L26.0957 18.4088C26.2609 18.2436 26.2609 17.9764 26.0957 17.8111L22.1933 13.9088C22.0246 13.7436 21.7574 13.7436 21.5921 13.9088ZM15.9636 22.3498C15.7703 22.1564 15.7703 21.8471 15.9636 21.6537L21.3777 16.2396C21.571 16.0463 21.8804 16.0463 22.0738 16.2396C22.2671 16.433 22.2671 16.7424 22.0738 16.9357L16.6597 22.3498C16.4664 22.5432 16.157 22.5432 15.9636 22.3498ZM14.6945 25.3064H16.382V26.5826L14.1144 26.9799L13.021 25.8865L13.4183 23.6189H14.6945V25.3064Z"
                                                                fill="white"
                                                            />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_6084_4534">
                                                                <rect
                                                                    width="18"
                                                                    height="18"
                                                                    fill="white"
                                                                    transform="translate(11.6016 10.3999)"
                                                                />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className=" flex items-center h-28 w-full gap-2 ">
                                                {/* <div className=""> */}
                                                {/* <p className=" text-dark mb-1 text-start font-13 fw-medium ">
                                                                            Logo
                                                                          </p>
                                                                          <img
                                                                            height={180}
                                                                            width={180}
                                                                            className="rounded-4 mt-2"
                                                                            src={
                                                                              cnvsImage ? cnvsImage : businessLogo
                                                                            }
                                                                            alt="loggo"
                                                                          />
                                                                        </div> */}

                                                <div
                                                    role="button"
                                                    onClick={() => setEditImgModal(true)}
                                                    className="w-full h-full border rounded-xl flex justify-center items-center p-[12px] text-center"
                                                >
                                                    <div>
                                                        <p className="mb-0 text-sm justify-center flex items-center gap-2 text-black inter point">
                                                            <FiUpload size={20} /> Upload Image
                                                        </p>
                                                        <p className=" text-[#718EBF] mb-0 text-xs  ">
                                                            * file types are JPG, PNG, PDF.
                                                        </p>
                                                    </div>
                                                </div>

                                                {/* <input type="file" onChange={(e) => { setNewBusinessLogo(e.target.files[0]); setBusinessLogo(URL.createObjectURL(e.target.files[0])) }} id="file" className="d-none" /> */}
                                            </div>
                                        )}
                                    </div>
                                    <div className="">
                                        <label className="text-sm mb-2 fw-medium">
                                            Location Name*
                                        </label>
                                        <input
                                            type="text"
                                            className="focus:outline-none placeholder:text-navyBlue text-navyBlue !text-sm border h-10 px-2 rounded-xl w-full"
                                            value={formData.name}
                                            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                            required
                                        />
                                    </div>
                                    <div className="">
                                        <label className="text-sm mb-2 fw-medium">
                                            Nick Name
                                        </label>
                                        <input
                                            type="text"
                                            className="focus:outline-none placeholder:text-navyBlue text-navyBlue !text-sm border h-10 px-2 rounded-xl w-full"
                                            value={formData.nick_name}
                                            onChange={(e) => setFormData({ ...formData, nick_name: e.target.value })}
                                        />
                                    </div>
                                    <div className="lg:col-span-2">
                                        <label className="text-sm mb-2 fw-medium">
                                            Description
                                        </label>
                                        <textarea
                                            className="focus:outline-none placeholder:text-navyBlue text-navyBlue !text-sm border p-2 rounded-xl w-full"
                                            rows="3"
                                            value={formData.description}
                                            onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                                        />
                                    </div>
                                    <div className="lg:col-span-2">
                                        <label className="text-sm mb-2 fw-medium">
                                            Address*
                                        </label>
                                        <input
                                            type="text"
                                            className="focus:outline-none placeholder:text-navyBlue text-navyBlue !text-sm border h-10 px-2 rounded-xl w-full"
                                            value={formData.address}
                                            onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                                            required
                                        />
                                    </div>
                                    <div className="">
                                        <label className="text-sm mb-2 fw-medium">
                                            City*
                                        </label>
                                        <input
                                            type="text"
                                            className="focus:outline-none placeholder:text-navyBlue text-navyBlue !text-sm border h-10 px-2 rounded-xl w-full"
                                            value={formData.city}
                                            onChange={(e) => setFormData({ ...formData, city: e.target.value })}
                                            required
                                        />
                                    </div>
                                    <div className="">
                                        <label className="text-sm mb-2 fw-medium">
                                            Postal Code*
                                        </label>
                                        <input
                                            type="text"
                                            className="focus:outline-none placeholder:text-navyBlue text-navyBlue !text-sm border h-10 px-2 rounded-xl w-full"
                                            value={formData.postal_code}
                                            onChange={(e) => setFormData({ ...formData, postal_code: e.target.value })}
                                            required
                                        />
                                    </div>
                                    <div className="">
                                        <label className="text-sm mb-2 fw-medium">
                                            Contact Email*
                                        </label>
                                        <input
                                            type="email"
                                            className="focus:outline-none placeholder:text-navyBlue text-navyBlue !text-sm border h-10 px-2 rounded-xl w-full"
                                            value={formData.contact_email}
                                            onChange={(e) => setFormData({ ...formData, contact_email: e.target.value })}
                                            required
                                        />
                                    </div>
                                    <div className="">
                                        <label className="text-sm mb-2 fw-medium">
                                            Contact Phone*
                                        </label>
                                        <input
                                            type="tel"
                                            className="focus:outline-none placeholder:text-navyBlue text-navyBlue !text-sm border h-10 px-2 rounded-xl w-full"
                                            value={formData.contact_phone}
                                            onChange={(e) => setFormData({ ...formData, contact_phone: e.target.value })}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="col-6 text-center">
                                        <button
                                            type="button"
                                            onClick={() => setShowForm(false)}
                                            className="bg-white w-full border border-navyBlue rounded-full text-navyBlue px-3 p-2 font-medium text-base"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                    <div className="col-6 text-center">
                                        <button
                                            type="submit"
                                            className="bg-customBlue w-full rounded-full text-white px-3 p-2 font-medium text-base"
                                        >
                                            {isLoading ? <Spinner size={"sm"} color={"light"} /> : "Create"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>


            {/* cover image   */}
            <Modal
                show={editImgModal}
                className="business_admin_main"
                centered
                onHide={() => setEditImgModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Upload Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {image && (
                            <>
                                <div className="imag_crop_shadow d-flex justify-content-center ">
                                    <AvatarEditor
                                        width={300}
                                        height={150}
                                        ref={previewRef}
                                        border={30}
                                        borderRadius={14}
                                        color={[80, 76, 76, 0.6]}
                                        scale={scale}
                                        rotate={rotate}
                                        image={URL.createObjectURL(image)}
                                    />
                                </div>
                                <div className="my-3 flex gap-2 items-center justify-center">
                                    <button
                                        role="button"
                                        className="btn-blue rounded-sm  w-5 h-5 flex justify-center items-center"
                                        onClick={() => setScale(scale - 0.1)}
                                    >
                                        <FiZoomOut />
                                    </button>
                                    <button
                                        role="button"
                                        className="btn-blue rounded-sm  w-5 h-5 flex justify-center items-center"
                                        onClick={() => setScale(scale + 0.1)}
                                    >
                                        <FiZoomIn />
                                    </button>

                                    <button
                                        role="button"
                                        className="btn-blue rounded-sm w-5 h-5 flex justify-center items-center"
                                        onClick={() => setRotate(rotate - 90)}
                                    >
                                        <FaArrowRotateLeft />
                                    </button>
                                    <button
                                        role="button"
                                        className="btn-blue rounded-sm  w-5 h-5 flex justify-center items-center"
                                        onClick={() => setRotate(rotate + 90)}
                                    >
                                        <FaArrowRotateRight />
                                    </button>
                                </div>
                            </>
                        )}

                        <div className=" inputgroup1 p-3 mt-1 text-center ">
                            <label htmlFor="file" className="h-100 w-100 cursor-pointer">
                                <div className="px-3 py-3 mb-3 ">
                                    <div className="flex justify-center">
                                        <img src={upload} width={60} alt="" />
                                    </div>
                                    <h6 className="font-15 font-500 inter colorblack mt-2 point">
                                        Drag &amp; drop files or{" "}
                                        <span className="colorblue text-decoration-underline">
                                            Browse
                                        </span>
                                    </h6>
                                    <p className="mb-0 mt-2 font-12 colorgray inter point">
                                        Supported formates: <br />
                                        JPEG, PNG
                                    </p>
                                </div>
                            </label>
                        </div>
                        <input
                            type="file"
                            id="file"
                            onChange={(e) => setImage(e.target.files[0])}
                            className="d-none"
                            accept="image/*"
                        />
                    </div>
                </Modal.Body>
                {image && (
                    <Modal.Footer>
                        <button
                            role="button"
                            className="btn-blue rounded-2 p-2 m-1"
                            onClick={handlePreview}
                        >
                            Upload
                        </button>
                    </Modal.Footer>
                )}
            </Modal>
            {/* --- logo crop */}
            <Modal
                show={editImgModalLogo}
                className="business_admin_main"
                centered
                onHide={() => setEditImgModalLogo(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Upload Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {imageLogo && (
                            <>
                                <div className="imag_crop_shadow d-flex justify-content-center ">
                                    <AvatarEditor
                                        width={300}
                                        height={150}
                                        ref={previewRefLogo}
                                        border={30}
                                        borderRadius={14}
                                        color={[80, 76, 76, 0.6]}
                                        scale={scaleLogo}
                                        rotate={rotateLogo}
                                        image={URL.createObjectURL(imageLogo)}
                                    />
                                </div>
                                <div className="my-3 flex gap-2 items-center justify-center">
                                    <button
                                        role="button"
                                        className="btn-blue rounded-sm  w-5 h-5 flex justify-center items-center"
                                        onClick={() => setScaleLogo(scaleLogo - 0.1)}
                                    >
                                        <FiZoomOut />
                                    </button>
                                    <button
                                        role="button"
                                        className="btn-blue rounded-sm  w-5 h-5 flex justify-center items-center"
                                        onClick={() => setScaleLogo(scaleLogo + 0.1)}
                                    >
                                        <FiZoomIn />
                                    </button>

                                    <button
                                        role="button"
                                        className="btn-blue rounded-sm w-5 h-5 flex justify-center items-center"
                                        onClick={() => setRotateLogo(rotateLogo - 90)}
                                    >
                                        <FaArrowRotateLeft />
                                    </button>
                                    <button
                                        role="button"
                                        className="btn-blue rounded-sm  w-5 h-5 flex justify-center items-center"
                                        onClick={() => setRotateLogo(rotateLogo + 90)}
                                    >
                                        <FaArrowRotateRight />
                                    </button>
                                </div>
                            </>
                        )}

                        <div className=" inputgroup1 p-3 mt-1 text-center ">
                            <label htmlFor="fileLogo" className="h-100 w-100 cursor-pointer">
                                <div className="px-3 py-3 mb-3 ">
                                    <div className="flex justify-center">
                                        <img src={upload} width={60} alt />
                                    </div>
                                    <h6 className="font-15 font-500 inter colorblack mt-2 point">
                                        Drag &amp; drop files or{" "}
                                        <span className="colorblue text-decoration-underline">
                                            Browse
                                        </span>
                                    </h6>
                                    <p className="mb-0 mt-2 font-12 colorgray inter point">
                                        Supported formates: <br />
                                        JPEG, PNG
                                    </p>
                                </div>
                            </label>
                        </div>
                        <input
                            type="file"
                            id="fileLogo"
                            onChange={(e) => setImageLogo(e.target.files[0])}
                            className="d-none"
                            accept="image/*"
                        />
                    </div>
                </Modal.Body>
                {imageLogo && (
                    <Modal.Footer>
                        <button
                            role="button"
                            className="btn-blue rounded-2 p-2 m-1"
                            onClick={handlePreviewLogo}
                        >
                            Upload
                        </button>
                    </Modal.Footer>
                )}
            </Modal>

            <ToastContainer />
        </section>
    );
}

export default Location;
