import React from "react";
import SideBar from "../../components/superAdmin/SideBar";
import CompanyBg from "../../assets/imges/companyImage.png";
import { useLocation } from "react-router-dom";
import entry from "../../assets/imges/entry.png";
import cafe from "../../assets/imges/cafepic.png";

import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Checkbox,
} from "@mui/material";
import { IoSearch } from "react-icons/io5";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from "react-icons/md";
import { FiDownload } from "react-icons/fi";
import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/zoom.css";
import link from "../../assets/imges/DeviceTableLink.png";
import Setting from "../../assets/imges/DeviceTableSetting.png";
import Toggle from "../../assets/imges/DeviceTableToggle.png";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Button } from "react-bootstrap";
import { deleteBusinessLocation, updateBusinessLocations } from "../../redux/slices/userSlice";
import { useDispatch } from "react-redux";

const ViewBusinessManagment = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(location.search);

  const email = queryParams.get("email");
  const { businessData } = location.state;
  const [user, setUser] = React.useState(false);
  const [userRadio, setUserRadio] = React.useState("New Customer");
  const [businessManagTab, setBusinessManagTab] = React.useState("orders");
  const isActive = (item) => {
    return businessManagTab === item
      ? "before:content-[''] before:absolute before:left-0 before:bottom-0 before:w-full before:h-1 before:bg-customBlue before:rounded-t-md text-customBlue font-medium"
      : "text-[#718EBF] font-medium";
  };
  function handleConnectDevice(locationId, device_id) {
    dispatch(updateBusinessLocations({
      device_id
    }, locationId))
  }
  return (
    <SideBar title={email || "NAN"}>
      <div className="w-full">
        <div>
          <div className="h-28">
            <img
              src={businessData?.cover_image}
              alt="Cover"
              className="h-48 w-full object-cover rounded-lg"
            />
          </div>
          <div className="px-4 flex items-end">
            <img
              src={businessData?.image}
              className="w-44 h-44 rounded-full"
              alt="Logo"
            />
            <h3 className="lg:text-3xl md:text-3xl text-2xl font-semibold ms-2 pb-3">{businessData?.business_name}</h3>
          </div>
        </div>
        <div className="w-full bg-white p-4 rounded-xl mt-12">
          <div className="mb-4">
            <div className=" d-flex align-items-end inter ps-0 border-b w-fit ">
              <button
                className={`relative ${isActive("orders")} py-2 px-6`}
                onClick={() => setBusinessManagTab("orders")}
                type="button"
              >
                Orders
              </button>

              <button
                className={`relative ${isActive("marketing")} py-2 px-6`}
                onClick={() => setBusinessManagTab("marketing")}
                type="button"
              >
                Marketing Campaigns
              </button>

              <button
                className={`relative ${isActive("devices")} py-2 px-6`}
                onClick={() => setBusinessManagTab("devices")}
                type="button"
              >
                Devices
              </button>
              <button
                className={`relative ${isActive("locations")} py-2 px-6`}
                onClick={() => setBusinessManagTab("locations")}
                type="button"
              >
                Locations
              </button>
            </div>
          </div>
          {businessManagTab === "orders" && (
            <div className="  white-border rounded-4 p-3">
              <div className="  inter overflow-x-auto">
                {/* =========table header ======== */}
                <div className="d-flex mt-3 justify-content-between  w-100 ">
                  <div
                    className="  d-flex flex-shrink-0 align-items-center justify-content-center "
                    style={{ width: 200 }}
                  >
                    <div className="d-flex align-items-center">
                      <IoSearch className="text-dark-gray" size={22} />
                      <input
                        type="search"
                        className="form-control ps-2 border-0 font-14 fw-medium py-1"
                        placeholder="Search"
                      />
                    </div>
                  </div>
                  <div className="table-cell  d-flex align-items-center justify-content-center position-relative ">
                    <span
                      role="button"
                      className="py-2 flex-1 whitespace-nowrap d-flex justify-content-between align-items-center rounded-3 font-12 fw-semibold   text-dark-gray  table-header-badges"
                      onClick={() => setUser(!user)}
                      style={{ paddingInline: "12px" }}
                    >
                      {userRadio}
                      {user ? (
                        <FaAngleDown className="ms-2" />
                      ) : (
                        <FaAngleUp className="ms-2" />
                      )}
                    </span>{" "}
                    <span className="ms-2 ">
                      <img
                        src={entry}
                        className="whitespace-nowrap"
                        width="5"
                        height="10"
                        alt="w8"
                      />
                    </span>
                    {user && (
                      <div
                        className="position-absolute bg-white p-2 px-2 shadow  top-100 end-0 rounded-4 z-1"
                        style={{ width: "110%" }}
                      >
                        <FormControl>
                          <RadioGroup
                            // defaultValue="All-time"
                            onChange={(e) => {
                              setUserRadio(e.target.value);
                              setUser(false);
                            }}
                            value={userRadio}
                            name="radio-buttons-user"
                          >
                            <FormControlLabel
                              value="All"
                              control={
                                <Radio
                                  sx={{
                                    color: "#1D59F9",
                                    "&.Mui-checked": {
                                      color: "#1D59F9   ",
                                    },
                                  }}
                                />
                              }
                              label="All"
                            />
                            <FormControlLabel
                              value="New Customer"
                              control={
                                <Radio
                                  sx={{
                                    color: "#1D59F9",
                                    "&.Mui-checked": {
                                      color: "#1D59F9   ",
                                    },
                                  }}
                                />
                              }
                              label="New Customer"
                            />
                            <FormControlLabel
                              value="Repeat Customer"
                              control={
                                <Radio
                                  sx={{
                                    color: "#1D59F9",
                                    "&.Mui-checked": {
                                      color: "#1D59F9   ",
                                    },
                                  }}
                                />
                              }
                              label="Repeat Customer"
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    )}
                  </div>
                  <div className="table-cell  d-flex align-items-center justify-content-center">
                    <span
                      className="p-2 rounded-3 font-12 fw-semibold  d-flex justify-content-between align-items-center text-dark-gray px-4 table-header-badges"
                      style={{ width: "70%" }}
                    >
                      Start <FaAngleDown />
                    </span>{" "}
                    <span className="ms-2">
                      <img src={entry} width="5" height="10" alt="w8" />
                    </span>
                  </div>
                  <div className="table-cell  d-flex align-items-center justify-content-center">
                    <span
                      className="p-2  text-center rounded-3 font-12 fw-semibold   text-dark-gray px-2 table-header-badges"
                      style={{ width: "70%" }}
                    >
                      End
                    </span>{" "}
                    <span className="ms-2">
                      <img src={entry} width="5" height="10" alt="w8" />
                    </span>
                  </div>
                  <div className="table-cell  d-flex align-items-center justify-content-center">
                    <span
                      className="p-2 text-center whitespace-nowrap rounded-3 font-12 fw-semibold   text-dark-gray px-4 table-header-badges"
                      style={{ width: "100%" }}
                    >
                      Total Time
                    </span>{" "}
                    <span className="ms-2">
                      <img src={entry} width="5" height="10" alt="w8" />
                    </span>
                  </div>
                  <div className="table-cell  d-flex align-items-center justify-content-center">
                    <span
                      className="p-2 text-center rounded-3 font-12 fw-semibold   text-dark-gray px-2 table-header-badges"
                      style={{ width: "70%" }}
                    >
                      Status
                    </span>{" "}
                    <span className="ms-2">
                      <img src={entry} width="5" height="10" alt="w8" />
                    </span>
                  </div>
                </div>
                {/* ============ table body ======== */}

                <div className="d-flex mt-3 justify-content-between  w-100 ">
                  <div className="table-cell-1 flex-shrink-0 d-flex align-items-center ">
                    <Checkbox
                      icon={
                        <MdRadioButtonUnchecked
                          color="rgba(0, 0, 0, 0.3)
"
                          size={20}
                        />
                      }
                      checkedIcon={<MdRadioButtonChecked size={20} />}
                    />
                    <div>
                      <p className="mb-0 font-14 fw-semibold ">Tesla</p>
                      <p className="mb-0 font-14 text-black-50 ">
                        Repeat Customer
                      </p>
                    </div>
                  </div>
                  <div className="table-cell  text-center  d-flex align-items-center justify-content-center">
                    Yes
                  </div>
                  <div className="table-cell   text-center  d-flex align-items-center justify-content-center">
                    <div>
                      <p className="mb-0 font-14 fw-medium  text-black-50 ">
                        January 20, 2023
                      </p>
                      <p className="mb-0 font-14 fw-medium  text-black-50 ">
                        12:15pm
                      </p>
                    </div>
                  </div>
                  <div className="table-cell  text-center  d-flex align-items-center justify-content-center">
                    <div>
                      <p className="mb-0 font-14 fw-medium  text-black-50 ">
                        January 20, 2023
                      </p>
                      <p className="mb-0 font-14 fw-medium  text-black-50 ">
                        12:15pm
                      </p>
                    </div>
                  </div>
                  <div className="table-cell  text-center  d-flex align-items-center justify-content-center ">
                    <p className="mb-0 font-14 fw-medium  text-black-50 ">
                      00:02.30
                    </p>
                  </div>
                  <div className="table-cell  text-center  d-flex align-items-center justify-content-center ">
                    <p className="mb-0 font-14 fw-medium  text-black-50 ">
                      Notified
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {businessManagTab === "marketing" && (
            <div
              className="rounded-xl border border-[#E4E5E7] p-3"
            >
              <p className="border-b pb-2 font-semibold">Campaign list</p>
              <div className="flex flex-wrap gap-5 justify-between items-center px-3">
                <div className=" w-80">
                  <img
                    src={cafe}
                    className=" object-fit-cover rounded-4 my-2 h-32 w-full"
                    alt="offer "
                  />
                  <h6 className="font-13 inter fw-semibold  colorblack mb-0">
                    Thank you for using Time Link:                  </h6>

                  <p className="mb-0 font-13 fw-medium inter text-dark-gray">
                    Congratulations! Enjoy a free coffee at Donald's Cafe. Claim your discount now. <u>Terms & Conditions apply. </u>
                  </p>

                </div>
                <div className=" d-flex align-align-items-center  text-center ">
                  <div>
                    <p className="font-30 lh-1  fw-bold mb-0">
                      0
                    </p>
                    <p className="font-20 mb-0  fw-bold text-gray">Reach</p>
                  </div>

                </div>
                <div className=" d-flex align-align-items-center  text-center">

                  <div className="">
                    <p className="font-30 lh-1 fw-bold mb-0">
                      0
                    </p>
                    <p className="font-20 mb-0 fw-bold text-gray">Sign-up</p>
                  </div>
                </div>
                <div className="business_admin_main ">
                  <div className="step-three">
                    <div
                      className="container-stepOne "
                      style={{ transform: "scale(1.2)" }}
                    >
                      <input
                        type="checkbox"
                        className="checkbox"
                        id={`checkbox-1`}

                      // checked={index.status == "active" ? true : false}
                      />
                      <label
                        className="switch"
                        htmlFor={`checkbox-1`}
                      >
                        <span className="slider" />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column  text-center mt-2 relative">



                  <Menu
                    menuButton={
                      <MenuButton>
                        <span
                          role="button"

                          className="d-flex align-items-center pe-2 rounded-3 bg-[#D9D9D9]"
                        >
                          <span
                            className="p-2 fw-bold font-12 border-r-[1.5px] border-[#E4E5E7]"

                          >
                            View
                          </span>
                          <span className="ps-2">
                            <FaAngleDown size={14} />
                          </span>
                        </span>
                      </MenuButton>
                    } direction="left"
                    align="center" position="anchor" arrow={true} gap={12}
                    shift={12}
                    transition
                    menuClassName="!min-w-fit"
                  >
                    <MenuItem>Edit</MenuItem>
                    <MenuItem>Duplicate</MenuItem>
                    <MenuItem>Delete</MenuItem>
                  </Menu>
                </div>
              </div>
            </div>
          )}
          {businessManagTab === "devices" &&
            businessData?.devices?.map((index) => {
              return (
                <div className="d-flex gap-5   justify-content-between  relative p-3 pt-lg-4 pt-5 bg-white rounded-[40px] flex-wrap mb-2 border">
                  <div className="inter d-flex gap-2 align-items-center whitespace-nowrap	">
                    <div className="px-2">
                      <input
                        type="checkbox"
                        className="!accent-[#65558F] !border-[#C8C3C9] !border-[1.5px] scale-150	"
                      />
                    </div>

                    <div className="text-gray-600">
                      <h3 className="text-xl mb-0">{index?.brand || "Time Link Connect"}</h3>
                      <p className="text-lg mb-0">{index?.topic_name || "TLC3891000001"}</p>
                      <h3 className="font-10 ">Created: {index?.created_at || " Aug 26, 2023"}</h3>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <button className="bg-green-500  text-white  rounded-5 w-24 p-2  font-14 text-capitalize">
                      {index?.status || "Active"}
                    </button>
                  </div>
                  <div className="d-flex align-items-center justify-content-between  ps-3 my-2 py-2 whitespace-nowrap	border-l-[1.5px] text-[#343C6A]">
                    <div>
                      <div className=" form-switch black-switch d-flex text-start align-items-center ps-0">
                        {/* <MdToggleOn className='grey' size={28} /> */}
                        <img src={Toggle} width={22} height={22} alt="w8" />

                        <p className="mb-0 font-12 fw-medium  ms-2">Active</p>
                      </div>
                      <div className="d-flex align-items-end mt-2 text-start">
                        {/* <FaHandHoldingUsd size={28} className='grey' /> */}
                        <img src={link} width={22} height={22} alt="w8" />
                        <p className="mb-0 font-12 fw-medium  ms-2">{index?.purchase_type || "Leased"}</p>
                      </div>
                      <div className="d-flex align-items-end mt-2 text-start">
                        {/* <AiFillSetting size={28} className='grey' /> */}
                        <img src={Setting} width={22} height={22} alt="w8" />

                        <p className="mb-0 font-12 fw-medium  ms-2">{index?.version || "Version 1"}</p>
                      </div>
                    </div>
                  </div>
                  <div className="deviceTableLastTD flex ">
                    {index?.problems && index?.problems?.length > 0 && (
                      <button
                        className={`${index?.problems[index?.problems.length - 1]?.priority.toLowerCase().includes('p2')
                          ? "bg-orange-600"
                          : index?.problems[index?.problems.length - 1]?.priority.toLowerCase().includes('p1')
                            ? "bg-red-600"
                            : index?.problems[index?.problems.length - 1]?.priority.toLowerCase().includes('p3')
                              ? "bg-yellow-600"
                              : "bg-green-600"
                          } fw-medium text-white !rounded-tr-[40px] !rounded-bl-[40px] p-2 px-5 text-capitalize  font-12 absolute top-0 right-0 w-fit`}
                      >
                        {index?.working_status}
                      </button>
                    )}

                    {/* <div className="d-flex gap-2 align-items-center justify-content-center mt-2">
                      <div className=" h-7 w-7 flex items-center justify-center rounded-lg bg-customBlue">
                        <FiDownload size={16} color="white" />
                      </div>

                      <Menu
                        menuButton={
                          <MenuButton>
                            <div className=" h-7 w-7 flex items-center justify-center rounded-lg bg-[#718EBF] ">
                              <BsThreeDotsVertical size={16} color="white" />
                            </div>
                          </MenuButton>
                        }
                        direction="left"
                        align="center"
                        position="anchor"
                        arrow={true}
                        gap={12}
                        shift={12}
                        transition
                        menuClassName="!min-w-fit !w-fit"
                      >
                        <MenuItem>Edit</MenuItem>
                        <MenuItem>Duplicate</MenuItem>
                        <MenuItem>Delete</MenuItem>
                      </Menu>
                    </div> */}
                    <div></div>
                  </div>
                </div>
              )
            })
          }
          {
            businessManagTab === 'locations' &&
            businessData?.locations?.map((location) => (
              <div key={location.id} className="d-flex flex-wrap border border-[grey] p-3 justify-content-between bg-white rounded-4 mt-2">
                <div className="inter d-flex align-items-center" style={{ whiteSpace: 'nowrap' }}>
                  <div>
                    <p className="font-18 mb-2" style={{ color: 'rgba(83, 83, 83, 1)' }}>{location.name} {'(' + location.nick_name + ')'}</p>
                    <h5 className="font-10" style={{ color: 'rgba(83, 83, 83, 1)' }}>{location.address}</h5>
                  </div>
                </div>
                <div className="d-flex flex-column gap-1 align-items-center justify-content-center">
                  {location.status === 'active' && (
                    <Button className='!bg-[#00BD13] px-2 py-1 rounded-5 font-15 fw-semibold text-capitalize !border-0'
                      style={{ whiteSpace: 'nowrap', minWidth: '100px' }}>
                      Active
                    </Button>
                  )}
                </div>

                <div className="d-flex align-items-center linkSetting ps-3 my-2 py-2" style={{ whiteSpace: 'nowrap' }}>
                  <div>
                    <div className="d-flex align-items-end mt-2 text-start">
                      <p className="mb-0 font-10 fw-medium ms-2" style={{ color: 'rgba(170, 170, 170, 1)' }}>
                        {location.city}, {location.city} {location.postal_code}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="d-flex align-items-center">
                  <div className="d-flex flex-column gap-1">
                    <Button className='!text-red-500 border-2 !border-red-500 text-capitalize fw-bold rounded-5 p-2 font-14 bg-white'
                      sx={{ minWidth: '100px' }}
                      style={{ borderColor: 'red' }}
                      onClick={() => dispatch(deleteBusinessLocation(location.id))}
                    >
                      Delete
                    </Button>
                  </div>
                </div>

                <div className='w-100 px-5 mt-4  text-sm flex flex-row gap-4'>
                  <div className='w-1/3'>
                    <h5>
                      Connect to QR Buzzer
                    </h5>
                    <div className="d-flex flex-column w-100  text-xs">
                      <p>
                        Select Device
                      </p>
                      <select
                        className="text-xs"
                        style={{
                          borderStyle: 'solid',
                          borderColor: '#C8C3C9',
                          borderWidth: 1,
                          padding: 10,
                          borderRadius: 25,
                        }}

                        onChange={(e) => {
                          if (e.target.value != 0) {
                            handleConnectDevice(location.id, e.target.value)
                          }
                        }}
                      >
                        <option value="0">Select a device to link </option>

                        {/* Render Primary Square Locations */}
                        {businessData?.devices?.map((device) => (
                          <option selected={location.device_id == device.id} key={`primary-${device?.id}`} className="text-xs" value={device?.id}>
                            {device?.serial_number}
                          </option>
                        ))}


                      </select>


                    </div>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </SideBar>
  );
};

export default ViewBusinessManagment;
