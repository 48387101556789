import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import './App.scss';
import ProtectedRoutes from './ProtectedRoutes';
import PublicRoutes from './PublicRoutes';
import SuperRoutes from './SuperRoutes';
import ScrollToTop from './components/ScrollToTop';
import Community from './pages/Community';
import HelpCenter from './pages/HelpCenter';
import Integrations from './pages/Integrations';
import IntegrationsDetail from './pages/IntegrationsDetail';
import NewOrder from './pages/NewOrder';
import NewOrderStats from './pages/NewOrderStats';
import NewPassword from './pages/NewPassword';
import Order from './pages/Order';
import OrderStatus from './pages/OrderStatus';
import Setting from './pages/Setting';
import Subscription from './pages/Subscription';
import Otp from './pages/businessSignup/Otp';
import SignUp from './pages/businessSignup/SignUp';
import SignUpAddress from './pages/businessSignup/SignUpAddress';
import SignUpContact from './pages/businessSignup/SignUpContact';
import SignUpEssentials from './pages/businessSignup/SignUpEssentials';
import SignUpProfile from './pages/businessSignup/SignUpProfile';
import Device from './pages/device/Device';
import NewDevice from './pages/device/NewDevice';
import Login from './pages/login/Login';
import Loyalty from './pages/loyalty/Loyalty';
import LoyaltyDetail from './pages/loyalty/LoyaltyDetail';
import NewLoyalty from './pages/loyalty/NewLoyalty';
import NewLoyaltyDetail from './pages/loyalty/NewLoyaltyDetail';
import CustomiseProgram from './pages/loyalty/points/CustomiseProgram';
import Review from './pages/loyalty/points/Review';
import Rewards from './pages/loyalty/points/Rewards';
import SelectProgramType from './pages/loyalty/points/SelectProgramType';
import Campaigns from './pages/marketingCampaigns/Campaigns';
import MarketingDetail from './pages/marketingCampaigns/MarketingDetail';
import NewCampaigns from './pages/marketingCampaigns/NewCampaigns';
import NewMarketingDetail from './pages/marketingCampaigns/NewMarketingDetail';
import CustomCompaignDetail from './pages/marketingCampaigns/customize-marketing/CustomCompaignDetail';
import CustomMarketingCompaign from './pages/marketingCampaigns/customize-marketing/CustomMarketingCompaign';
import ReviewMarketing from './pages/marketingCampaigns/customize-marketing/ReviewMarketing';
import TargetAudience from './pages/marketingCampaigns/customize-marketing/TargetAudience';
import TargetAudienceStepTwo from './pages/marketingCampaigns/customize-marketing/TargetAudienceStepTwo';
import NewLogin from './pages/newAuth/NewLogin';
import NewSignUp from './pages/newAuth/NewSignUp';
import ResetHelpWay from './pages/resetPassword/ResetHelpWay';
import ResetPassword from './pages/resetPassword/ResetPassword';
import RewardsList from './pages/rewards/RewardsList';
import BusinessManagment from './pages/superAdminPanel/BusinessManagment';
import SuperDevices from './pages/superAdminPanel/Devices';
import Frame from './pages/superAdminPanel/Frame';
import LoyaltyProgram from './pages/superAdminPanel/LoyaltyProgram';
import ManageSurveys from './pages/superAdminPanel/ManageSurveys';
import Marketing from './pages/superAdminPanel/Marketing';
import NewBusiness from './pages/superAdminPanel/NewBusiness';
import Users from './pages/superAdminPanel/Users';
import Feedback from './pages/surveys/Feedback';
import SurveyDetail from './pages/surveys/SurveyDetail';
import Surveys from './pages/surveys/Surveys';
import ForgotPasswordWay from './pages/trialAuth/ForgotPasswordWay';
import TrialForgot from './pages/trialAuth/TrialForgot';
import TrialLogin from './pages/trialAuth/TrialLogin';
import TrialOtpVerify from './pages/trialAuth/TrialOtpVerify';
import TrialSignup from './pages/trialAuth/TrialSignup';
import WellcomeFive from './pages/trialAuth/WellcomeFive';
import WellcomeFour from './pages/trialAuth/WellcomeFour';
import WellcomeOne from './pages/trialAuth/WellcomeOne';
import WellcomeThree from './pages/trialAuth/WellcomeThree';
import WellcomeTwo from './pages/trialAuth/WellcomeTwo';
import { getUser } from './redux/slices/userSlice';
import OnboardingNumber from './pages/onboarding/OnboardingNumber';
import OnboardingNumberVerify from './pages/onboarding/OnboardingNumberVerify';
import Reviewing from './pages/onboarding/Reviewing';
import NewStripe from './pages/trialAuth/NewStripe';
import ViewBusinessManagment from './pages/superAdminPanel/ViewBusinessManagment';
import UserManagment from './pages/superAdminPanel/UserManagment';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Blogs from './pages/superAdminPanel/blog/Blogs';
import BlogAdd from './pages/superAdminPanel/blog/BlogAdd';
import MarketingRoutes from './MarketingRoutes';
import Location from './pages/locations/Location';

const theme = createTheme({
  palette: {
    mode: 'light', // or 'dark'
  },
});

// const FRIGADE_API_KEY = "api_public_M5t0tSjBwurkWLROh7L4RZ1Wqw8qds2s7nY7n3n0AVq5qYU5pMZweahD9Vyx8w5u";
function App() {
  const { userDetail } = useSelector(
    (state) => state.user
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUser())
  }, [])

  return (
    <ThemeProvider theme={theme}>
      {/* <Frigade.Provider
        apiKey={FRIGADE_API_KEY}
        userId={String(userDetail.id || Math.floor(Math.random() * 9999))}
        > */}
      <>
        <ScrollToTop />
        <Routes>
          <Route exact path="/onboarding/number" element={<OnboardingNumber />} />
          <Route exact path="/onboarding/number/verify" element={<OnboardingNumberVerify />} />
          <Route exact path="/onboarding/reviewing" element={<Reviewing />} />
          <Route exact path="/auth" element={<PublicRoutes />}>
            <Route exact path='/auth/trial/login' element={<Login />} />
            <Route exact path='/auth/trial/signup' element={<SignUp />} />
            <Route exact path='/auth/trial/otpverify' element={<Otp />} />
            <Route exact path='/auth/signup/Profile' element={<SignUpProfile />} />
            <Route exact path='/auth/signup/essentials' element={<SignUpEssentials />} />
            <Route exact path='/auth/signup/Address' element={<SignUpAddress />} />
            <Route exact path='/auth/signup/Contact' element={<SignUpContact />} />
            <Route exact path='/auth/reset' element={<ResetPassword />} />
            <Route exact path='/auth/reset/help' element={<ResetHelpWay />} />
            <Route exact path='/auth/password/new/:token' element={<NewPassword />} />
            <Route exact path='/auth/login' element={<TrialLogin />} />
            <Route exact path='/auth/signup' element={<TrialSignup />} />
            <Route exact path='/auth/otpverify' element={<TrialOtpVerify />} />
            <Route exact path='/auth/trial/forgot' element={<TrialForgot />} />
            <Route exact path='/auth/trial/forgotPasswordWay' element={<ForgotPasswordWay />} />
            <Route exact path='/auth/profile/set' element={<WellcomeOne />} />
            {/* <Route exact path='/auth/profile/business-name' element={<WellcomeTwo />} />
          <Route exact path='/auth/profile/abn' element={<WellcomeThree />} />
          <Route exact path='/auth/profile/logo' element={<WellcomeFour />} />
          <Route exact path='/auth/profile/category' element={<WellcomeFive />} /> */}

          </Route>
          <Route exact path="/super" element={<SuperRoutes />}>
            {/* ======== SUPER ADMIN START ========= */}
            <Route exact path='/super/newBusiness' element={<NewBusiness />} />
            <Route exact path='/super/device' element={<SuperDevices />} />
            <Route exact path='/super/businessManagment' element={<BusinessManagment />} />
            <Route exact path='/super/businessManagment/:id' element={<ViewBusinessManagment />} />
            <Route exact path='/super/user' element={<Users />} />
            <Route exact path='/super/userManagment' element={<UserManagment />} />
            <Route exact path='/super/marketing' element={<Marketing />} />
            <Route exact path='/super/loyalty' element={<LoyaltyProgram />} />
            <Route exact path='/super/survey' element={<ManageSurveys />} />
            <Route path="/super/blog" element={<Blogs />} />
            <Route path="/super/blog/add" element={<BlogAdd />} />
            {/* ======== SUPER ADMIN END ========= */}
          </Route>
          <Route exact path="/marketing" element={<MarketingRoutes />}>
            {/* ======== MARKETING START ========= */}

            <Route path="/marketing/blog" element={<Blogs />} />
            <Route path="/marketing/blog/add" element={<BlogAdd />} />
            {/* ======== MARKETING END ========= */}
          </Route>


          <Route exact path="/" element={<ProtectedRoutes />}>
            {/* <Route path='/' element={<Community />} /> */}
            <Route exact path="/onboarding/number" element={<OnboardingNumber />} />
            <Route exact path="/onboarding/number/verify" element={<OnboardingNumberVerify />} />
            <Route exact path="/onboarding/reviewing" element={<Reviewing />} />
            <Route exact path='/profile/set' element={<WellcomeOne />} />
            <Route exact path='/profile/business-name' element={<WellcomeTwo />} />
            <Route exact path='/profile/abn' element={<WellcomeThree />} />
            <Route exact path='/profile/logo' element={<WellcomeFour />} />
            <Route exact path='/profile/category' element={<WellcomeFive />} />
            <Route exact path='/subscription/payment' element={<NewStripe />} />
            <Route path='/order' element={<Order />} />
            <Route path='/locations' element={<Location />} />
            <Route path='/order/status' element={<OrderStatus />} />
            <Route path='/marketing/campaigns' element={<Campaigns />} />
            <Route path='/loyalty' element={<Loyalty />} />
            <Route path='/device' element={<Device />} />
            <Route path='/surveys' element={<Surveys />} />
            <Route path='/surveyDetail' element={<SurveyDetail />} />
            <Route path='/feedback' element={<Feedback />} />
            <Route path='/setting' element={<Setting />} />
            <Route path='/helpCenter' element={<HelpCenter />} />
            <Route path='/subscription' element={<Subscription />} />
            <Route path='/marketing/campaigns/detail' element={<MarketingDetail />} />
            <Route path='/loyalty/detail' element={<LoyaltyDetail />} />
            <Route exact path='/get/placeid' element={<Frame />} />


            {/* ================ ReDesign Routes ============= */}
            <Route path='/' element={<NewOrder />} />
            {/* <Route path='/order/new' element={<NewOrder />} /> */}
            <Route path='/order/stats/new' element={<NewOrderStats />} />
            <Route path='/marketing/campaigns/new' element={<NewCampaigns />} />
            <Route path='/marketing/campaigns/detail/new' element={<NewMarketingDetail />} />
            {/* <Route path='/loyalty/new' element={<NewLoyalty />} />
          <Route path='/loyalty/detail/new' element={<NewLoyaltyDetail />} /> */}
            <Route path='/device/new' element={<NewDevice />} />
            <Route path='/login/new' element={<NewLogin />} />
            <Route path='/signUp/new' element={<NewSignUp />} />

            <Route path='/costomiseProgram' element={<CustomiseProgram />} />
            <Route path='/selectProgramType' element={<SelectProgramType />} />
            <Route path='/rewards' element={<RewardsList />} />
            <Route path='/rewards/create' element={<Rewards />} />
            <Route path='/review' element={<Review />} />
            <Route path='/custom/marketing/campaign' element={<CustomMarketingCompaign />} />
            <Route path='/custom/marketing/detail' element={<CustomCompaignDetail />} />
            <Route path='/custom/marketing/targetAudience' element={<TargetAudience />} />
            <Route path='/custom/marketing/targetAudience/second/step' element={<TargetAudienceStepTwo />} />
            <Route path='/custom/marketing/review' element={<ReviewMarketing />} />
            <Route path='/integrations' element={<Integrations />} />
            <Route path='/integrations/detail/:id' element={<IntegrationsDetail />} />
          </Route>
        </Routes>
      </>
      {/* </Frigade.Provider> */}
    </ThemeProvider>
  );
}

export default App;
