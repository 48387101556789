/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import Header from "../components/Header";
import SideBar from "../components/SideBar";
import { GoTriangleDown } from "react-icons/go";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Checkbox,
} from "@mui/material";
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { FaCalendarDays, FaUsers } from "react-icons/fa6";
import { LuAlarmClock } from "react-icons/lu";
import { BsFileEarmarkSpreadsheetFill } from "react-icons/bs";
import { PieChart } from "react-minimal-pie-chart";
import { IoSearch } from "react-icons/io5";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import entry from "../assets/imges/entry.png";
import progress from "../assets/imges/progress-button.png";
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import $ from "jquery"; // Import jQuery
import { getBusinessOrders, seTgetErrorNull } from "../redux/slices/userSlice";
import "bootstrap-daterangepicker/daterangepicker.css"; // Import DateRangePicker CSS
import "bootstrap-daterangepicker/daterangepicker"; // Import DateRangePicker JavaScript
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getTotalTimeForOrder } from "../helpers/helpers";

const data = [
  { name: "6am", uv: 0 },
  { name: "7am", uv: 120 },
  { name: "8am", uv: 80 },
  { name: "9am", uv: 100 },
  { name: "10am", uv: 80 },
  { name: "11am", uv: 400 },
  { name: "12am", uv: 170 },
  { name: "1am", uv: 500 },
  { name: "2am", uv: 430 },
  { name: "3am", uv: 300 },
  { name: "4am", uv: 280 },
  { name: "5am", uv: 450 },
];
const piaChart = [
  { title: "1,350", reach: "Sign-ups", value: 40, color: "#2B65F8" },
  { title: "4,500", reach: "Impressions", value: 50, color: "#DDE6FE" },
  { title: "UnKnown", reach: "UnKnown", value: 10, color: "#EBEBEB" },
];
const piaChartTwo = [
  { reach: "Mail", value: 40, color: "#2B65F8" },
  { reach: "Femail", value: 60, color: "#F79DE8" },
  { reach: "Other", value: 24, color: "#EBEBEB" },
];
const colorsForGenderChart = ["#2B65F8", "#F79DE8", "#EBEBEB"];
const piaChartAge = [
  { reach: "18-24", value: 40, color: "#2B65F8" },
  { reach: "25-34", value: 60, color: "#DDE6FE" },
  { reach: "35-44", value: 24, color: "#EE3CD2" },
  { reach: "45-54", value: 24, color: "rgba(238, 60, 210, 0.5)" },
  { reach: "60+", value: 24, color: "rgba(4, 194, 92, 0.5)" },
  { reach: "Unknown", value: 24, color: "rgba(235, 235, 235, 1)" },
];
const colorsForAgeChart = [
  "#2B65F8",
  "#DDE6FE",
  "#EE3CD2",
  "rgba(238, 60, 210, 0.5)",
  "rgba(4, 194, 92, 0.5)",
  "rgba(235, 235, 235, 1)",
];
// const CustomTick = (props) => {
//     const { x, y, payload } = props;
//     return (
//         <text x={x} y={y + 10} fontSize="12" className='poppins fw-medium' textAnchor="middle" fill="#A3AED0">

//             {payload.value}
//         </text>
//     );
// };
const arrowStyle = {
  position: "absolute",
  left: "18%", // Center the arrow
  transform: "translateX(-50%)", // Center the arrow
  top: "100%", // Adjust this value as needed
  width: "15px",
  height: "8px",
  background: "white", // Arrow color
  clipPath: "polygon(0% 0%, 50% 100%, 100% 0%)", // Arrow shape
};
const CustomTooltip = ({ active, payload, avwt, bp }) => {
  if (active && payload && payload.length) {
    const uvValue = payload[0].value;
    // const arrowStyle = {
    //     position: 'absolute',
    //     left: '18%',     // Center the arrow
    //     transform: 'translateX(-50%)',  // Center the arrow
    //     top: '100%',  // Adjust this value as needed
    //     width: '15px',
    //     height: '8px',
    //     background: 'white',  // Arrow color
    //     clipPath: 'polygon(0% 0%, 50% 100%, 100% 0%)',  // Arrow shape
    // };
    return (
      <div
        className="custom-tooltip  rounded-3 p-1 px-2 border-0 "
        style={{
          backgroundColor: "white",
          filter: "drop-shadow(6px 12px 35px rgba(0, 0, 0, 0.08))",
        }}
      >
        {/* <div className="tooltip-arrow" style={arrowStyle} /> */}
        <p className="label mb-0  poppins font-12 fw-semibold">
          <span>
            <FaUsers className="blue" />
          </span>{" "}
          Orders:
          <span className="fw-normal">{` ${payload[0].value}`}</span>
        </p>
        <p className="label mb-0  poppins font-12 fw-semibold">
          <span>
            <LuAlarmClock className="blue" />
          </span>{" "}
          Average Wait:
          <span className="fw-normal">{avwt}</span>
        </p>
        <p className="label mb-0  poppins font-12 fw-semibold">
          <span>
            <BsFileEarmarkSpreadsheetFill className="blue" />
          </span>{" "}
          {bp} : <span className="fw-normal">Busiest period</span>
        </p>
      </div>
    );
  }

  return null;
};
const NewOrderStats = () => {
  const [user, setUser] = React.useState(false);
  const [userRadio, setUserRadio] = React.useState("New Customer");
  const dispatch = useDispatch();
  const { isLoading, getError, businessOrders, selectedLocation } = useSelector(
    (state) => state.user
  );
  let [barGraphData, setBarGraphData] = useState({});
  let [mainGraph, setMainGraph] = useState(null);
  const [donutChart, setDonutChart] = useState();
  const [repeatCustomerChart, setRepeatCustomerChart] = useState();
  const [ageChart, setAgeChart] = useState();
  const [genderChart, setGenderChart] = useState();

  useEffect(() => {
    if (businessOrders?.homeScreenData) {
      setRepeatCustomerChart([
        {
          title: businessOrders?.homeScreenData?.timeBasedUninqueCustomers,
          reach: "New Customers",
          value: businessOrders?.homeScreenData?.timeBasedUninqueCustomers,
          color: "#2B65F8",
        },
        {
          title: businessOrders?.homeScreenData?.timeBasedRepeatCustomers,
          reach: "Repeat Customers",
          value: businessOrders?.homeScreenData?.timeBasedRepeatCustomers,
          color: "#DDE6FE",
        },
      ]);
    }
    if (businessOrders?.homeScreenData?.genderChartData) {
      setGenderChart(
        businessOrders?.homeScreenData?.genderChartData.map((index, key) => {
          return {
            reach: index.gender_group,
            value: index.count,
            color: colorsForGenderChart[key],
          };
        })
      );
    }
    if (businessOrders?.homeScreenData?.ageChartData) {
      setAgeChart(
        businessOrders?.homeScreenData?.ageChartData.map((index, key) => {
          return {
            reach: index.age_group,
            value: index.count,
            color: colorsForAgeChart[key],
          };
        })
      );
    }
    if (businessOrders?.homeScreenData?.graphData) {
      let transformedData = [];

      // Iterate over the original data object
      for (const timeSlot in businessOrders?.homeScreenData?.graphData) {
        if (
          businessOrders?.homeScreenData?.graphData.hasOwnProperty(timeSlot)
        ) {
          // Creating an object with 'title' and 'value' keys
          transformedData.push({
            title: convertTimeRange(timeSlot),
            orders: businessOrders?.homeScreenData?.graphData[timeSlot],
          });
        }
      }
      console.log("main graph data ", transformedData);
      setMainGraph(transformedData);
    }
  }, [businessOrders]);
  // ========== DATEPICKER =============
  let $datepicker;
  let datepickerInstance; // Store the DateRangePicker instance

  const datepickerRef = useRef(null);
  const calendarIconRef = useRef(null);

  useEffect(() => {
    // Define predefined date ranges
    const predefinedRanges = {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
      "Last 7 Days": [moment().subtract(6, "days"), moment()],
      "Last 30 Days": [moment().subtract(29, "days"), moment()],
      "Last 90 Days": [moment().subtract(89, "days"), moment()],
      "This Month": [moment().startOf("month"), moment().endOf("month")],
      "Last Month": [
        moment().subtract(1, "month").startOf("month"),
        moment().subtract(1, "month").endOf("month"),
      ],
    };

    // Initialize the DateRangePicker with predefined ranges
    $datepicker = $(datepickerRef?.current);

    datepickerInstance = $datepicker
      .daterangepicker({
        ranges: predefinedRanges,
        opens: "center", // Adjust the position of the calendar
      })
      .data("daterangepicker");

    // Specify any additional configurations or event handlers here if needed
    // Event handler for when the user applies a new date range
    const handleDateRangeChange = (event, picker) => {
      // Retrieve the selected start and end dates
      const startDate = picker.startDate.format("YYYY-MM-DD");
      const endDate = picker.endDate.format("YYYY-MM-DD");

      // Do something with the selected dates, such as updating state or calling a function
      console.log("Selected start date:", startDate);
      console.log("Selected end date:", endDate);
      dispatch(getBusinessOrders(selectedLocation, startDate, endDate));
    };

    // Attach the event handler to the 'apply.daterangepicker' event
    $datepicker.on("apply.daterangepicker", handleDateRangeChange);

    // Clean up the DateRangePicker instance when the component unmounts
    return () => {
      if (datepickerInstance) {
        datepickerInstance.remove();
        $datepicker.off("apply.daterangepicker", handleDateRangeChange);
      }
    };
  }, []);

  const handleIconClick = () => {
    if (datepickerInstance) {
      datepickerInstance.show(); // Open the date range picker
    }
  };

  useEffect(() => {
    if (getError && getError.length > 1) {
      toast.error(getError, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      dispatch(seTgetErrorNull());

    }
  }, [getError]);
  useEffect(() => {
    if (selectedLocation) {
      dispatch(getBusinessOrders(selectedLocation));
    }
  }, [selectedLocation]);

  function convertTimeRange(inputString) {
    // Split the input string by ' - ' to separate start and end times
    const times = inputString.split(" - ");

    // Extract the start and end times
    const startTime = times[0];
    const endTime = times[1];

    // Extract the hour part and the period indicator (AM or PM)
    const startHour = parseInt(startTime);
    const endHour = parseInt(endTime);

    // Format the start and end times
    const startFormatted = `${startHour % 12 || 12}-${endHour % 12 || 12
      }${endTime.slice(-2)[0].toLowerCase()}`;

    return startFormatted;
  }
  function getTitleWithMaxOrders(data) {
    let maxOrders = -1;
    let maxTitle = "";

    data.forEach((item) => {
      if (item.orders > maxOrders) {
        maxOrders = item.orders;
        maxTitle = item.title;
      }
    });

    return maxOrders > 0 ? maxTitle : false;
  }
  return (
    <>
      <div className="container-fluid  business_admin_main">
        <div className="row order">
          {/* <!-- ===========HEADER========== --> */}
          {/* <!-- ===========SID-BAR======= --> */}
          <SideBar />
          {/* <!-- ========= content body ======= --> */}
          <div className="w-lg-80 px-0 h-screen overflow-y-scroll">
            <Header PageTitle="Orders" activeState={"statistics"} />
            <div className="px-lg-4 px-md-3 px-2">
              <div className="container-fluid p-0 pb-4 mt-4">
                <div className="row justify-content-between new-select-option g-0">
                  <div className="col-3 position-relative">
                    {/* <div className=' stats_select rounded-5  d-flex align-items-center white-border '
                                        onClick={() => { setTimeframe(!timeframe); handleIconClick() }}>
                                        <p className='mb-0 me-1 text-dark-gray fw-medium '>Timeframe:</p>
                                        <div className='d-flex align-items-center justify-content-between w-100'>

                                            {timeframeRadio}
                                            <GoTriangleDown />
                                        </div>
                                    </div> */}
                    {/*
                                        timeframe &&
                                        <input
                                            type="text"
                                            ref={datepickerRef}
                                            id='datePicker'
                                            className="form-control border-0 rounded-3 text-gray"
                                            placeholder="Select Date Range"
                                        />
                                    */}
                    <div className="d-flex align-items-center rounded-5 white-border">
                      <label
                        ref={calendarIconRef}
                        onClick={handleIconClick}
                        htmlfor="datePicker"
                        className="ps-2 text-gray mb-1"
                      >
                        <FaCalendarDays />
                      </label>
                      <input
                        type="text"
                        ref={datepickerRef}
                        id="datePicker"
                        className="form-control border-0 rounded-5 text-gray"
                        placeholder="Select Date Range"
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-4  ">
                    <div className="white-border h-100 rounded-4 p-2">
                      <div className="container-fluid h-100">
                        <div className="row h-100">
                          <div className="col-6">
                            <div className="d-flex flex-column h-100">
                              <div className="flex-grow-1">
                                <p className="font-14 fw-medium">
                                  Repeat Customer Rate
                                </p>
                                <h4 className="fw-bold">
                                  {businessOrders?.homeScreenData
                                    ?.timeBasedRepeatCustomerRate || 0}
                                  %
                                </h4>
                              </div>
                              {/* <br /> */}
                              {repeatCustomerChart && (
                                <div>
                                  {repeatCustomerChart.map((entry, index) => (
                                    <div
                                      className="flex-shrink-0"
                                      key={index}
                                      style={{
                                        display: "flex",
                                        alignItems: "baseline",
                                        whiteSpace: "nowrap",
                                        marginTop: "3px",
                                      }}
                                    >
                                      <div
                                        className="rounded-1 flex-shrink-0"
                                        style={{
                                          width: "18px",
                                          height: "10px",
                                          backgroundColor: entry.color,
                                          marginRight: "5px",
                                        }}
                                      ></div>
                                      <div>
                                        <p className="mb-0 font-13 fw-bold">
                                          {" "}
                                          {entry.title}{" "}
                                          <span className="fw-normal">
                                            {entry.reach}
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          </div>
                          {repeatCustomerChart && (
                            <div className="col-6">
                              <PieChart
                                data={repeatCustomerChart}
                                lineWidth={40}
                                paddingAngle={5}
                              // rounded={1}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4  ">
                    <div className="white-border h-100 rounded-4 p-2">
                      <div className="container-fluid h-100">
                        <div className="row h-100">
                          <div className="col-6">
                            <div className="d-flex h-100 flex-column">
                              <div className="flex-grow-1">
                                <p className="font-14 fw-medium">Gender</p>
                              </div>
                              <div>
                                {genderChart &&
                                  genderChart.map((entry, index) => (
                                    <div
                                      className="flex-shrink-0 "
                                      key={index}
                                      style={{
                                        display: "flex",
                                        alignItems: "baseline",
                                        whiteSpace: "nowrap",
                                        marginTop: "3px",
                                      }}
                                    >
                                      <div
                                        className="rounded-1 flex-shrink-0"
                                        style={{
                                          width: "18px",
                                          height: "10px",
                                          backgroundColor: entry.color,
                                          marginRight: "5px",
                                        }}
                                      ></div>
                                      <div>
                                        <p className="mb-0 font-13 fw-bold">
                                          {" "}
                                          {entry.value}%{" "}
                                          <span className="fw-normal text-capitalize">
                                            {entry.reach}
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                          <div className="col-6">
                            {genderChart && (
                              <PieChart
                                data={genderChart}
                                lineWidth={40}
                                paddingAngle={5}
                              // rounded={1}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4  ">
                    <div className="white-border h-100 rounded-4 p-2">
                      <div className="container-fluid h-100">
                        <div className="row h-100">
                          <div className="col-6">
                            <div className="d-flex h-100 flex-column">
                              <div className="flex-grow-1">
                                <p className="font-14 mb-2 fw-medium">
                                  Age Group
                                </p>
                              </div>
                              {ageChart && (
                                <div>
                                  {ageChart.map((entry, index) => (
                                    <div
                                      className="flex-shrink-0 "
                                      key={index}
                                      style={{
                                        display: "flex",
                                        alignItems: "baseline",
                                        whiteSpace: "nowrap",
                                        marginTop: "3px",
                                      }}
                                    >
                                      <div
                                        className="rounded-1 flex-shrink-0"
                                        style={{
                                          width: "18px",
                                          height: "10px",
                                          backgroundColor: entry.color,
                                          marginRight: "5px",
                                        }}
                                      ></div>
                                      <div>
                                        <p className="mb-0 font-13 fw-bold">
                                          {" "}
                                          {entry.title}{" "}
                                          <span className="fw-normal">
                                            {entry.reach}
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-6">
                            {ageChart && (
                              <PieChart
                                data={ageChart}
                                lineWidth={40}
                                paddingAngle={5}
                              // rounded={1}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row g-2 mt-3">
                  <div className="col-6 new-order-linechart">
                    <div className="white-border p-3 rounded-4">
                      <div
                        className="pb-3 "
                        style={{ borderBottom: "1.5px solid #E4E5E7" }}
                      >
                        <h6 className="font-14  mb-0 ">Orders Activity</h6>
                      </div>
                      {mainGraph && (
                        <LineChart
                          width={500}
                          height={220}
                          data={mainGraph}
                          margin={{
                            top: 10,
                            right: 15,
                            left: -28,
                            bottom: 0,
                          }}
                          axisLine={false}
                        >
                          <defs>
                            <linearGradient
                              id="linear-gradient"
                              x1="100%"
                              y1="0%"
                              x2="0%"
                              y2="100%"
                            >
                              <stop offset="13.44%" stop-color="#1B59F8" />
                              <stop
                                offset="104%"
                                stop-color="rgba(27, 89, 248, 0)"
                              />
                            </linearGradient>
                          </defs>
                          <CartesianGrid stroke={false} />
                          <XAxis
                            dataKey="title"
                            tick={{
                              fontFamily: "Inter,sans",
                              fontWeight: "500",
                              fill: "#838383",
                              fontSize: 9,
                            }}
                            interval={0}
                            axisLine={false}
                          />
                          <YAxis
                            axisLine={false}
                            tick={{
                              fontFamily: "Inter,sans",
                              fontWeight: "500",
                              fill: "#838383",
                              fontSize: 9,
                            }}
                          />
                          {/* <Tooltip /> */}
                          <Tooltip
                            content={
                              <CustomTooltip
                                avwt={
                                  businessOrders?.homeScreenData
                                    ?.timeBasedaverageWaitTime
                                }
                                bp={
                                  mainGraph && getTitleWithMaxOrders(mainGraph)
                                    ? getTitleWithMaxOrders(mainGraph) + "m"
                                    : "N/A"
                                }
                              />
                            }
                          />

                          <Line
                            connectNulls
                            type="monotone"
                            strokeWidth={6}
                            dataKey="orders"
                            stroke="url(#linear-gradient)"
                            dot={false}
                            border
                          />
                        </LineChart>
                      )}
                    </div>
                  </div>
                  <div className="col-4  ">
                    <div className="white-border  rounded-4 p-2">
                      <div className="container-fluid h-100">
                        <div className="row h-100">
                          <div className="col-6">
                            <div className="d-flex flex-column h-100">
                              <div className="flex-grow-1">
                                <p className="font-14 fw-medium text-dark-gray">
                                  Average Conversion Rate
                                </p>
                                <h4 className="fw-bold">30%</h4>
                              </div>
                              <br />
                              <br />
                              <div>
                                {piaChart.slice(0, 2).map((entry, index) => (
                                  <div
                                    className="flex-shrink-0"
                                    key={index}
                                    style={{
                                      display: "flex",
                                      alignItems: "baseline",
                                      whiteSpace: "nowrap",
                                      marginTop: "3px",
                                    }}
                                  >
                                    <div
                                      className="rounded-1 flex-shrink-0"
                                      style={{
                                        width: "18px",
                                        height: "10px",
                                        backgroundColor: entry.color,
                                        marginRight: "5px",
                                      }}
                                    ></div>
                                    <div>
                                      <p className="mb-0 font-13 fw-bold">
                                        {" "}
                                        {entry.title}{" "}
                                        <span className="fw-normal">
                                          {entry.reach}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                          <div className="col-6">
                            <PieChart
                              data={piaChart}
                              lineWidth={40}
                              paddingAngle={5}
                            // rounded={1}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-2 d-flex flex-column justify-content-between ">
                    <div className="white-border  rounded-4 p-3">
                      <p className="font-14 fw-medium  text-dark-gray mb-1">
                        Total Orders
                      </p>
                      <h4 className="fw-bold mb-0">
                        {businessOrders?.homeScreenData?.timeBasedOrders.length}
                      </h4>
                    </div>
                    <div className="white-border  rounded-4 p-3">
                      <p className="font-14 fw-medium  text-dark-gray mb-1">
                        Average wait-time
                      </p>
                      <h4 className="fw-bold mb-0">
                        {
                          businessOrders?.homeScreenData
                            ?.timeBasedaverageWaitTime
                        }
                      </h4>
                    </div>
                    <div className="white-border  rounded-4 p-3">
                      <p className="font-14 fw-medium  text-dark-gray mb-1">
                        Busiest period
                      </p>
                      <h4 className="fw-bold mb-0">
                        {mainGraph && getTitleWithMaxOrders(mainGraph)
                          ? getTitleWithMaxOrders(mainGraph) + "m"
                          : "N/A"}
                      </h4>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className=" mt-3 white-border rounded-4 p-3">
                      <div
                        className="d-flex pb-3 align-items-center justify-content-between "
                        style={{
                          borderBottom: "2px solid rgba(244, 245, 247, 1)",
                        }}
                      >
                        <div>
                          <h6 className="font-18 fw-semibold mb-0 ">
                            Todays’ Orders
                            <span className="ms-4 text-gray">
                              {
                                businessOrders?.homeScreenData?.timeBasedOrders
                                  .length
                              }
                            </span>
                          </h6>
                        </div>
                        <img src={progress} width={35} height={35} alt="w8" />
                      </div>
                      <div className="custom-table inter overflow-x-scroll hide-scrollbar ">
                        {/* =========table header ======== */}
                        <div className="d-flex mt-3 justify-content-between  w-100 ">
                          <div
                            className="  d-flex flex-shrink-0 align-items-center justify-content-center "
                            style={{ width: 200 }}
                          >
                            <div className="d-flex align-items-center">
                              <IoSearch className="text-dark-gray" size={22} />
                              <input
                                type="search"
                                className="form-control ps-2 border-0 font-14 fw-medium py-1"
                                placeholder="Search"
                              />
                            </div>
                          </div>
                          <div className="table-cell  d-flex align-items-center justify-content-center position-relative ">
                            <span
                              role="button"
                              className="py-2 d-flex justify-content-between align-items-center rounded-3 font-12 fw-semibold   text-dark-gray  table-header-badges"
                              onClick={() => setUser(!user)}
                              style={{ width: "85%", paddingInline: "12px" }}
                            >
                              {userRadio}
                              {user ? (
                                <FaAngleDown className="ms-2" />
                              ) : (
                                <FaAngleUp className="ms-2" />
                              )}
                            </span>{" "}
                            <span className="ms-2">
                              <img src={entry} width="5" height="10" alt="w8" />
                            </span>
                            {user && (
                              <div
                                className="position-absolute bg-white p-2 px-2 shadow  top-100 end-0 rounded-4 z-1"
                                style={{ width: "110%" }}
                              >
                                <FormControl>
                                  <RadioGroup
                                    // defaultValue="All-time"
                                    onChange={(e) => {
                                      setUserRadio(e.target.value);
                                      setUser(false);
                                    }}
                                    value={userRadio}
                                    name="radio-buttons-user"
                                  >
                                    <FormControlLabel
                                      value="All"
                                      control={
                                        <Radio
                                          sx={{
                                            color: "#1D59F9",
                                            "&.Mui-checked": {
                                              color: "#1D59F9   ",
                                            },
                                          }}
                                        />
                                      }
                                      label="All"
                                    />
                                    <FormControlLabel
                                      value="New Customer"
                                      control={
                                        <Radio
                                          sx={{
                                            color: "#1D59F9",
                                            "&.Mui-checked": {
                                              color: "#1D59F9   ",
                                            },
                                          }}
                                        />
                                      }
                                      label="New Customer"
                                    />
                                    <FormControlLabel
                                      value="Repeat Customer"
                                      control={
                                        <Radio
                                          sx={{
                                            color: "#1D59F9",
                                            "&.Mui-checked": {
                                              color: "#1D59F9   ",
                                            },
                                          }}
                                        />
                                      }
                                      label="Repeat Customer"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </div>
                            )}
                          </div>
                          <div className="table-cell  d-flex align-items-center justify-content-center">
                            <span
                              className="p-2 rounded-3 font-12 fw-semibold  d-flex justify-content-between align-items-center text-dark-gray px-4 table-header-badges"
                              style={{ width: "70%" }}
                            >
                              Start <FaAngleDown />
                            </span>{" "}
                            <span className="ms-2">
                              <img src={entry} width="5" height="10" alt="w8" />
                            </span>
                          </div>
                          <div className="table-cell  d-flex align-items-center justify-content-center">
                            <span
                              className="p-2  text-center rounded-3 font-12 fw-semibold   text-dark-gray px-2 table-header-badges"
                              style={{ width: "70%" }}
                            >
                              End
                            </span>{" "}
                            <span className="ms-2">
                              <img src={entry} width="5" height="10" alt="w8" />
                            </span>
                          </div>
                          <div className="table-cell  d-flex align-items-center justify-content-center">
                            <span
                              className="p-2 text-center rounded-3 font-12 fw-semibold   text-dark-gray px-4 table-header-badges"
                              style={{ width: "70%" }}
                            >
                              Total Time
                            </span>{" "}
                            <span className="ms-2">
                              <img src={entry} width="5" height="10" alt="w8" />
                            </span>
                          </div>
                          <div className="table-cell  d-flex align-items-center justify-content-center">
                            <span
                              className="p-2 text-center rounded-3 font-12 fw-semibold   text-dark-gray px-2 table-header-badges"
                              style={{ width: "70%" }}
                            >
                              Status
                            </span>{" "}
                            <span className="ms-2">
                              <img src={entry} width="5" height="10" alt="w8" />
                            </span>
                          </div>
                        </div>
                        {/* ============ table body ======== */}
                        {businessOrders?.homeScreenData?.timeBasedOrders.map(
                          (value, index) => {
                            return (
                              <div className="d-flex mt-3 justify-content-between  w-100 ">
                                <div className="table-cell-1 flex-shrink-0 d-flex align-items-center ">
                                  <Checkbox
                                    icon={
                                      <MdRadioButtonUnchecked
                                        color="rgba(0, 0, 0, 0.3)
"
                                        size={20}
                                      />
                                    }
                                    checkedIcon={
                                      <MdRadioButtonChecked size={20} />
                                    }
                                  />
                                  <div>
                                    <p className="mb-0 font-14 fw-semibold ">
                                      {value.order_id}
                                    </p>
                                    <p className="mb-0 font-14 text-black-50 ">
                                      Repeat Customer
                                    </p>
                                  </div>
                                </div>
                                <div className="table-cell  text-center  d-flex align-items-center justify-content-center text-capitalize">
                                  {value.isRepeating ? "Yes" : "No"}
                                </div>
                                <div className="table-cell   text-center  d-flex align-items-center justify-content-center">
                                  <div>
                                    <p className="mb-0 font-14 fw-medium  text-black-50 ">
                                      {value.start_time}
                                    </p>
                                    {/* <p className='mb-0 font-14 fw-medium  text-black-50 '>12:15pm</p> */}
                                  </div>
                                </div>
                                <div className="table-cell  text-center  d-flex align-items-center justify-content-center">
                                  <div>
                                    <p className="mb-0 font-14 fw-medium  text-black-50 ">
                                      {value.end_time}
                                    </p>
                                    {/* <p className='mb-0 font-14 fw-medium  text-black-50 '>12:15pm</p> */}
                                  </div>
                                </div>
                                <div className="table-cell  text-center  d-flex align-items-center justify-content-center ">
                                  <p className="mb-0 font-14 fw-medium  text-black-50 ">
                                    {getTotalTimeForOrder(
                                      value.start_time,
                                      value.end_time
                                    ) == "N/A"
                                      ? "00:00:00"
                                      : getTotalTimeForOrder(
                                        value.start_time,
                                        value.end_time
                                      )}
                                  </p>
                                </div>
                                <div className="table-cell  text-center  d-flex align-items-center justify-content-center ">
                                  <p className="mb-0 font-14 fw-medium  text-black-50 ">
                                    {value.status}
                                  </p>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default NewOrderStats;
