import React, { useEffect, useState } from "react";
import { FormControl, MenuItem, Select } from "@mui/material";
import TableDownload from "../../../assets/imges/downloadDeviceTable.png";
import { HiSearch } from "react-icons/hi";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { Input, Spinner } from "reactstrap";
import DeviceListCard from "./DeviceListCard";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiFillPlusCircle } from "react-icons/ai";
import { FiDownload, FiSearch } from "react-icons/fi";
import { seTgetErrorNull } from "../../../redux/slices/userSlice";
export default function DevicesTable() {
  const dispatch = useDispatch();
  const { isLoading, getError, businessDevices } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    if (getError && getError.length > 1) {
      toast.error(getError, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      dispatch(seTgetErrorNull());

    }
  }, [getError]);

  const [deviceTab, setDeviceTab] = useState("all");
  const isActive = (item) => {
    return deviceTab === item
      ? "before:content-[''] before:absolute before:left-0 before:bottom-0 before:w-full before:h-1 before:bg-customBlue before:rounded-t-md text-customBlue font-medium"
      : "text-[#718EBF] font-medium";
  };
  return (
    <>
      <div className="container-fluid">
        <div className="w-full flex justify-between flex-wrap gap-2 mb-4 justify-content-between">
          <div className=" d-flex flex-wrap align-items-end inter ps-0 border-b">
            <button
              className={`relative ${isActive("all")} py-2 px-6`}
              onClick={() => setDeviceTab("all")}
              type="button"
            >
              All
            </button>

            <button
              className={`relative ${isActive("devices")} py-2 px-6`}
              onClick={() => setDeviceTab("devices")}
              type="button"
            >
              Devices
            </button>

            <button
              className={`relative ${isActive("tablets")} py-2 px-6`}
              onClick={() => setDeviceTab("tablets")}
              type="button"
            >
              Tablets
            </button>

            <button
              className={`relative ${isActive("favourites")} py-2 px-6`}
              onClick={() => setDeviceTab("favourites")}
              type="button"
            >
              Favourites
            </button>
          </div>
          <div className=" ">
            <div className="border h-10 flex items-center whitespace-nowrap gap-1 w-48 px-2 rounded-full bg-white text-sm">
              <p className="m-0">Sort by:</p>
              <select className="flex-1 border-0 focus:outline-none h-full rounded-full text-customBlue font-medium ">
                <option selected>Most Recent</option>
                <option value="1">One</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className=" table-scroll">
        <div className="d-flex  devices-table px-1 p-2 my-2  ">
          <div className="d-flex align-items-center">
            <div className="px-3">
              <input
                type="checkbox"
                className="!accent-[#65558F] !border-[#C8C3C9] !border-[1.5px] scale-150	"
              />
            </div>
          </div>
          <div className="w-full flex-wrap flex gap-2 items-center">
            <div className="border flex items-center justify-center h-10  w-32 px-2 rounded-xl bg-white">
              <FiSearch />
              <input
                type="search"
                className="focus:outline-none w-100 border-0 ps-1 text-sm placeholder:text-navyBlue"
                placeholder="Search.."
              />
            </div>
            <div className="border h-10 w-32 px-2 rounded-xl bg-white">
              <select className="w-full border-0 focus:outline-none h-full text-navyBlue text-sm">
                <option selected>Brand</option>
                <option value="1">One</option>
              </select>
            </div>
            <div className="border h-10 w-32 px-2 rounded-xl bg-white">
              <select className="w-full border-0 focus:outline-none h-full text-navyBlue text-sm">
                <option selected>Status</option>
                <option value="1">One</option>
              </select>
            </div>
            <div className="border h-10 w-32 px-2 rounded-xl bg-white">
              <select className="w-full border-0 focus:outline-none h-full text-navyBlue text-sm">
                <option selected>Purchase</option>
                <option value="1">One</option>
              </select>
            </div>
            <div className="border h-10 w-32 px-2 rounded-xl bg-white">
              <select className="w-full border-0 focus:outline-none h-full text-navyBlue text-sm">
                <option selected>Version</option>
                <option value="1">One</option>
              </select>
            </div>
            <div className=" h-10 w-10 flex items-center justify-center rounded-xl bg-customBlue">
              <FiDownload size={20} color="white" />
            </div>
          </div>
        </div>

        {/* ------------ALl---------- */}
        {deviceTab === "all" && (

          <div>
            {isLoading ? (
              <div className="row justify-content-center not-active-Campaigns rounded-3 text-center mt-3">
                <div className="col-12">
                  <div className="Campaigns p-4 rounded-3">
                    <Spinner color={"dark"} />{" "}
                  </div>
                </div>
              </div>
            ) : businessDevices.length < 1 ? (
              <div className="row justify-content-center not-active-Campaigns rounded-3 text-center mt-3">
                <div className="col-12">
                  <div className="Campaigns p-4 rounded-3">
                    <h5 className="fw-bold">No Devices</h5>
                  </div>
                </div>
              </div>
            ) : (
              businessDevices.map((index) => {
                return <DeviceListCard data={index} deviceId={index.id} />;
              })
            )}
          </div>
        )}

        {/* ----DEVICES---------- */}
        {deviceTab === "devices" && (

          <div>
            <DeviceListCard data={null} />
          </div>
        )}

        {/* --------TABLETS------------------ */}
        {deviceTab === "tablets" && (

          <div>
            <DeviceListCard data={null} />
          </div>
        )}
        {/* ------------FAVOURIT---------- */}
        {deviceTab === "favourites" && (

          <div>
            <DeviceListCard data={null} />
          </div>
        )}
      </div>

      <ToastContainer />
    </>
  );
}
